import { ProgressWrapper } from '@ljn/reclame'
import PropTypes from '@ljn/prop-types'
import React from 'react'

import * as Styled from 'shared/components/loader/__styles__/Loader.styles'

const Loader = ({ ariaLabel }) => {
	return (
		<Styled.Root>
			<ProgressWrapper ariaLabel={ariaLabel}>
				<Styled.Loader />
			</ProgressWrapper>
		</Styled.Root>
	)
}

Loader.propTypes = {
	ariaLabel: PropTypes.string.isRequired,
}

export default Loader
