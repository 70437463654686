import PropTypes from '@ljn/prop-types'
import React, { useEffect, useRef } from 'react'

import * as Styled from 'shared/components/tabs/__styles__/TabItem.styles'

const TabItem = ({ index, activeTab, label, path, onKeyDownTab }) => {
	const ref = useRef()
	const mounted = useRef(false)

	useEffect(() => {
		if (!!mounted.current && activeTab === index) {
			ref.current.focus()
		}
		mounted.current = true
	}, [activeTab, index])

	const _onKeyDown = (index) => (e) => onKeyDownTab(e, index)

	return (
		<Styled.Root role="none" $isSelected={activeTab === index}>
			<Styled.Link
				data-testid={`__tab-button-${index}__`}
				ref={ref}
				type="button"
				role="navitem"
				aria-selected={activeTab === index}
				tabIndex={activeTab === index ? 0 : -1}
				aria-controls={`tabpanel-${index}`}
				to={path}
				onKeyDown={_onKeyDown(index)}
			>
				{label}
			</Styled.Link>
		</Styled.Root>
	)
}

TabItem.propTypes = {
	/**
	 * A callback called  when a key is pressed on the component.
	 * Allows you to define the active tab and keyboard navigation.
	 * */
	onKeyDownTab: PropTypes.func.isRequired,
	label: PropTypes.string.isRequired,
	path: PropTypes.string.isRequired,
	index: PropTypes.number.isRequired,
	/** Allows to define if the component is selected or not according to the index prop.
	 * The color changes depending on this condition.
	 * */
	activeTab: PropTypes.number.isRequired,
}

export default TabItem
