import { Link as _Link } from 'react-router-dom'
import styled from '@xstyled/styled-components'

export const Root = styled.li`
	border-bottom: ${({ $isSelected, theme }) => `0.125rem solid ${$isSelected ? theme.colors.primary6 : 'transparent'}`};
	padding: 0.75rem 0 0.87rem;
	${({ $isSelected, theme }) => ($isSelected ? `color: ${theme.colors.primary6}` : `color: ${theme.colors.grey4}`)};
`

export const Link = styled(_Link)`
	background-color: backgroundSecondary;
	cursor: pointer;
	border-width: 0;
	transition: all ease-in-out 0.2s;
	box-shadow: unset;
	${({ theme }) => ({ ...theme.fonts.medium })};
`
