import { useTheme } from '@xstyled/styled-components'
import AsyncCreatableSelect from 'react-select/async-creatable'
import AsyncSelect from 'react-select/async'
import PropTypes from '@ljn/prop-types'
import React from 'react'
import debounce from 'es6-promise-debounce'

import * as Styled from 'shared/components/autoComplete/__styles__/AutoComplete.styles'

const AutoComplete = ({
	name,
	source,
	sourceParser,
	value,
	placeholder,
	options,
	isSearchable,
	isClearable,
	isTagMode,
	noOptionsMessage,
	loadingMessage,
	onChange,
	onError,
	...props
}) => {
	const theme = useTheme()

	const loadOptions = async (input, callback) => {
		try {
			let data = await source?.get(input)
			callback(!!sourceParser ? sourceParser(data) : data)
		} catch (error) {
			onError?.(error)
		}
	}

	const _onChange = (value) => {
		onChange?.(value)
	}

	return (
		<Styled.Root
			{...props}
			component={isTagMode ? <AsyncCreatableSelect /> : <AsyncSelect />}
			{...(isTagMode ? { formatCreateLabel: (userInput) => userInput, createOptionPosition: 'first' } : {})}
			name={name}
			placeholder={placeholder}
			value={value}
			loadOptions={debounce(loadOptions, 500)}
			options={options}
			components={{
				DropdownIndicator: () => null,
				IndicatorSeparator: () => null,
			}}
			styles={{
				control: () => ({
					...theme.fonts.formField,
					display: 'flex',
					appearance: 'none',
					width: '100%',
					height: '100%',
					overflow: 'hidden',
				}),
				menu: (base) => ({
					...base,
					backgroundColor: theme.colors.backgroundSecondary,
					borderRadius: theme.radii.regular,
					boxShadow: theme.shadows.medium,
					left: 0,
					right: 0,
				}),
				menuList: (base) => ({
					...base,
					maxHeight: '10.5rem',
				}),
				option: (base, { isSelected, isFocused }) => ({
					...theme.fonts.formField,
					cursor: 'pointer',
					background: isSelected || isFocused ? theme.colors.backgroundPrimary : 'transparent',
					'&:hover': {
						backgroundColor: theme.colors.backgroundPrimary,
						color: theme.fonts.formField.color,
					},
					lineHeight: '2rem',
					padding: '0 0.5rem',
				}),
				groupHeading: () => ({
					...theme.fonts.regularBold,
					marginBottom: '0.2rem',
					padding: '0 0.5rem',
				}),
				clearIndicator: (base) => ({
					...base,
					paddingRight: 0,
				}),
			}}
			noOptionsMessage={() => noOptionsMessage}
			loadingMessage={() => loadingMessage}
			isSearchable={isSearchable}
			isClearable={isClearable}
			onChange={_onChange}
		/>
	)
}

AutoComplete.propTypes = {
	name: PropTypes.string,
	source: PropTypes.shape({
		get: PropTypes.func.isRequired,
	}).isRequired,
	sourceParser: PropTypes.func,
	value: PropTypes.any,
	placeholder: PropTypes.string,
	options: PropTypes.shape({
		label: PropTypes.string,
		value: PropTypes.any,
	}),
	/** Defines if the Select manage tag or menu. */
	isTagMode: PropTypes.bool,
	noOptionsMessage: PropTypes.string,
	loadingMessage: PropTypes.string,
	isSearchable: PropTypes.bool,
	isClearable: PropTypes.bool,
	onChange: PropTypes.func,
	onError: PropTypes.func,
}

AutoComplete.defaultProps = {
	name: null,
	sourceParser: null,
	value: null,
	placeholder: null,
	options: null,
	noOptionsMessage: null,
	loadingMessage: null,
	isSearchable: true,
	isClearable: true,
	isTagMode: false,
	onChange: null,
	onError: null,
}

export default AutoComplete
