import { Callout as _Callout } from '@ljn/reclame'
import styled from '@xstyled/styled-components'

import _Logo from 'shared/components/logo/Logo'

export const Page = styled.div`
	height: 105vh;

	background-color: var(--g300);
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: space-between;

	> *:nth-child(2) {
		width: 100%;
	}
	a {
		background-image: none;
	}
`

export const Content = styled.div`
	width: clamp(360px, 48vw, 600px);
	max-height: calc(100vh - 2rem);
	padding: 3rem 2rem;
	margin-top: 2rem;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	align-items: stretch;

	box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.1), 0 8px 16px -16px rgba(0, 0, 0, 0.32);
	background-color: var(--w);

	> a:first-child {
		display: flex;
	}
`

export const PasswordPage = styled.div`
	padding: 2rem 1.5rem;
`

export const Logo = styled(_Logo)`
	margin-bottom: 4rem;
	margin-left: auto;
	margin-right: auto;
	width: clamp(300px, 36vw, 400px);
	text-align: center;
`

export const Notice = styled(_Callout)`
	&:last-of-type {
		margin-bottom: 1rem;
	}
`

export const Notification = styled(_Callout)`
	margin-bottom: 2rem;
`
