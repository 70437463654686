import PropTypes from '@ljn/prop-types'
import React from 'react'

import * as Styled from 'shared/components/page/__styles__/Page.styles'

const Page = ({ children, ...props }) => {
	return <Styled.Root {...props}>{children}</Styled.Root>
}

Page.propTypes = {
	children: PropTypes.node,
}

export default Page
