const defaultColors = [
	{ color: '#A41421' },
	{ color: '#CAC5B0' },
	{ color: '#C8D6A7' },
	{ color: '#8BCDB0' },
	{ color: '#A3B4B2' },
	{ color: '#80D5C6' },
	{ color: '#ABB8DF' },
	{ color: '#A3A6BC' },
	{ color: '#FFC6BE' },
	{ color: '#E7C5BB' },
	{ color: '#FFE0CF' },
	{ color: '#FFF37F' },
	{ color: '#FFCC9F' },
	{ color: '#F0C5B1' },
	{ color: '#FFB7A5' },
	{ color: '#BEA7AD' },
	{ color: '#D1B4AC' },
	{ color: '#BFBFBF' },
]

export default defaultColors
