import { Button as _Button, Checkbox as _Checkbox, TextField as _TextField } from '@ljn/reclame'
import _DatePicker from 'react-datepicker'
import styled from '@xstyled/styled-components'

import 'react-datepicker/dist/react-datepicker.css'

export const Root = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 100%;
	flex: 1;
	overflow: hidden;
`

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	flex: 1;
	width: 100%;
	overflow: hidden;
`

export const Content = styled.div`
	display: flex;
	flex: 1;
	padding: 1.5rem;
	width: 100%;
	justify-content: flex-start;
	flex-direction: column;
	overflow-y: auto;
`

export const Footer = styled.div`
	position: relative;
	display: flex;
	width: 100%;
	border-top: light;
	align-items: center;
	padding: 0.625rem 1rem;
	justify-content: flex-end;
	column-gap: 0.5rem;
`

export const ButtonDefault = styled(_Button)``

export const FieldTitle = styled.h5`
	${({ theme }) => ({ ...theme.fonts.bold })};
	margin: 0;
`

export const FirstFieldTitle = styled(FieldTitle)`
	margin: 0 0 1rem;
`

export const SecondFieldTitle = styled(FieldTitle)`
	margin: 2.5rem 0 1.5rem;
`

export const Checkbox = styled(_Checkbox)`
	margin-bottom: 2.5rem;
	margin-top: 0 !important;
`

export const DatePicker = styled(_DatePicker)`
	height: 2rem;
	border: medium;
	border-radius: regular;
	width: 100%;
	padding: 0.3125rem 0.75rem;

	:hover,
	:focus,
	:focus-visible,
	:focus-within {
		outline: none;
		border-color: primary5;
	}
`

export const Calendar = styled.div`
	overflow: hidden;
	width: 100%;
	background-color: backgroundSecondary;
	${({ theme }) => ({ ...theme.fonts.formField })};

	.react-datepicker__day--in-selecting-range {
		background-color: primary2;
		border-radius: medium;
		color: ${({ theme }) => ({ ...theme.fonts.formField.color })};
	}

	.react-datepicker__day--keyboard-selected,
	.react-datepicker__day--in-range {
		background-color: primary6;
		border-radius: medium;
		color: backgroundSecondary;
	}

	.react-datepicker__day:hover {
		background-color: backgroundPrimary;
		border-radius: medium;
	}

	.react-datepicker__header {
		background-color: background1;
	}

	.react-datepicker__current-month {
		${({ theme }) => ({ ...theme.fonts.bold })};
	}
`

export const TextField = styled(_TextField)``

export const Row = styled.span`
	display: flex;
	flex-direction: row;
	column-gap: 1.5rem;
	flex-wrap: wrap;

	& > div {
		width: calc(50% - (1.5rem / 2));
	}
`

export const RowFullSize = styled(Row)`
	width: 100%;
`

export const Field = styled.div`
	display: flex;
	flex-direction: column;
	width: calc(50% - (1.5rem / 2));

	.react-datepicker__input-container {
		width: 100%;
	}

	.react-datepicker__triangle {
		display: none;
	}

	.react-datepicker__close-icon:after {
		background-color: primary6;
		color: backgroundSecondary;
	}
`

export const SliderField = styled(Field)`
	width: 100%;
`

export const FieldLabel = styled.label`
	${({ theme }) => ({ ...theme.fonts.formField })};
`

export const FieldLabelWithMargin = styled(FieldLabel)`
	margin-bottom: 0.5rem;
`

export const FieldDescription = styled.span`
	${({ theme }) => ({ ...theme.fonts.medium })};
	color: grey3;
	margin-bottom: 1.1875rem;
`

export const SelectTagContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 1.5rem;

	> div {
		width: 100%;
	}
`

export const Slider = styled.input`
	-webkit-appearance: none;
	width: calc(50% - (1.5rem / 2));
	height: 0.25rem;
	border-radius: 5px;
	outline: none;
	margin-right: 0.25rem;

	:hover {
		cursor: pointer;
	}

	::-webkit-slider-thumb {
		-webkit-appearance: none;
		appearance: none;
		width: 0.875rem;
		height: 0.875rem;
		border-radius: 50%;
		border-color: ${({ theme, disabled }) => (disabled ? theme.colors.background1 : theme.colors.primary5)};
		background-color: backgroundSecondary;
		border-width: 0.125rem;
		border-style: solid;
		cursor: pointer;
	}

	::-moz-range-thumb {
		width: 0.875rem;
		height: 0.875rem;
		border-radius: 50%;
		border-color: primary5;
		border-width: 0.125rem;
		border-style: solid;
		background-color: backgroundSecondary;
		cursor: pointer;
	}
`

export const SliderLabels = styled.div`
	width: calc(50% - (1.5rem / 2));
	display: flex;
	justify-content: space-between;
	${({ theme }) => ({ ...theme.fonts.small })};
	${({ theme, disabled }) => disabled && `color: ${theme.colors.backgroundPrimary}`};

	span:first-child {
		text-align: start;
	}

	span:nth-child(2) {
		text-align: center;
	}

	span:last-child {
		text-align: end;
	}
`

export const SliderContent = styled.div`
	display: flex;
	width: 100%;
	height: 0.25rem;
	align-items: center;
`

export const SliderLabel = styled.span`
	margin-top: 0.25rem;
	flex: 1;
`

export const Space = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	row-gap: 2.5rem;
`

export const IconButton = styled(_Button)`
	&& {
		background: none;
		padding: 0 !important;
		color: grey2;
		min-height: unset !important;
		max-height: unset !important;
		max-width: unset !important;
		--recl-icon-size: 1rem !important;
		height: 1rem;

		:hover,
		:active {
			background-image: unset !important;
			color: primary5 !important;
		}
	}
`

export const LoadingContainer = styled.div`
	z-index: loading;
	position: absolute;
	background-color: backgroundPrimary;
	opacity: 0.5;
	width: 100%;
	height: 100%;
`
export const Input = styled.input`
	${({ theme }) => ({ ...theme.fonts.formField })};
	border: medium;
	border-radius: regular;
	padding: 0 0.5rem;
	min-height: 2rem;
`
