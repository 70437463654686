import produce from 'immer'

import { PROJECTS_SIZE } from 'shared/enums/paginationsTypes'

import {
	FETCH_PUBLISHED_PROJECTS_SUCCESS,
	POST_FILTERS,
	POST_FILTERS_SUCCESS,
	RETRIEVE_COLORS_SUCCESS,
	RETRIEVE_USER_COLORS_SUCCESS,
	SET_HOVERED_PROJECT,
	STARTUP_SUCCESS,
	STORE_USER_COLORS,
	TOGGLE_CHECKED_PROJECTS_SUCCESS,
} from 'modules/root/actions/rootActions'

export const initialState = {
	isAppReady: false,
	colors: [],
	userColors: {},
	isPostFiltersLoading: false,
	hoveredProject: null,
	projects: {
		count: 0,
		data: [],
		index: 0,
		limit: PROJECTS_SIZE,
		pageCount: 0,
		total: 0,
		projectsNotFinished: 0,
		projectsFinished: 0,
	},
	checkedProjects: [],
	filters: {
		projectProgression: [],
		deploymentArea: [],
		topics: [],
		targetedAudience: [],
		startDate: null,
		endDate: null,
		maxBudget: null,
		minBeneficiaries: null,
		maxBeneficiaries: null,
		fundingScope: [],
		localisation: null,
		q: null,
	},
}

export default function rootReducer(state, action) {
	return produce(state || initialState, (draft) => {
		switch (action.type) {
			case STARTUP_SUCCESS: {
				draft.isAppReady = true
				break
			}
			case TOGGLE_CHECKED_PROJECTS_SUCCESS: {
				draft.checkedProjects = action.payload.projectIds
				break
			}
			case FETCH_PUBLISHED_PROJECTS_SUCCESS: {
				const { data, ...others } = action.payload.projects
				draft.projects.data = !!action.payload.projects.index
					? [...draft.projects.data, ...action.payload.projects.data]
					: action.payload.projects.data

				Object.keys(others).forEach((key) => {
					draft.projects[key] = action.payload.projects[key]
				})
				break
			}
			case POST_FILTERS: {
				draft.isPostFiltersLoading = true
				break
			}
			case POST_FILTERS_SUCCESS: {
				draft.isPostFiltersLoading = false
				draft.checkedProjects = initialState.checkedProjects
				Object.keys(action.payload.filters).forEach((key) => {
					draft.filters[key] = action.payload.filters[key]
				})
				break
			}
			case RETRIEVE_COLORS_SUCCESS: {
				draft.colors = action.payload.colors
				break
			}
			case STORE_USER_COLORS: {
				draft.userColors = action.payload.colors
				break
			}
			case RETRIEVE_USER_COLORS_SUCCESS: {
				draft.userColors = action.payload.colors
				break
			}
			case SET_HOVERED_PROJECT: {
				draft.hoveredProject = action.payload.projectId
				break
			}
			default: {
				return state || initialState
			}
		}
	})
}
