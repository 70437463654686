import { isNumber } from 'lodash'

export const formatCurrency = (value) => {
	if (!isNumber(value)) {
		return null
	}

	return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(value)
}

export const formatPercent = (value, stripSpace = false) => {
	if (!isNumber(value)) {
		return null
	}

	let result = Intl.NumberFormat('fr-FR', { style: 'percent' }).format(value)
	if (stripSpace) {
		result = result.replace(/\s%/, '%')
	}

	return result
}

export const formatToPartsLinear = (value, formatCurrency = false) => {
	if (!isNumber(value)) {
		return null
	}

	const options = {
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
		...(formatCurrency && { style: 'currency', currency: 'EUR' }),
	}

	return new Intl.NumberFormat('fr-FR', options).format(value)
}
