import PropTypes from '@ljn/prop-types'
import React from 'react'

import * as Styled from 'shared/components/progressBar/__styles__/ProgressBar.styles'

const ProgressBar = ({ id, currentValue, ariaLabel, inactiveProgressColor }) => {
	return (
		<Styled.Root id={id} data-testid={id} ariaLabel={ariaLabel} value={currentValue} total="100">
			<Styled.ProgressRectangle isActive={currentValue >= 25} inactiveProgressColor={inactiveProgressColor} />
			<Styled.ProgressRectangle isActive={currentValue >= 50} inactiveProgressColor={inactiveProgressColor} />
			<Styled.ProgressRectangle isActive={currentValue >= 75} inactiveProgressColor={inactiveProgressColor} />
			<Styled.ProgressRectangle isActive={currentValue === 100} inactiveProgressColor={inactiveProgressColor} />
			<Styled.ProgressLabel>{currentValue}%</Styled.ProgressLabel>
		</Styled.Root>
	)
}

ProgressBar.propTypes = {
	id: PropTypes.string,
	currentValue: PropTypes.number.isRequired,
	ariaLabel: PropTypes.string.isRequired,
	/** Used to manage the color of inactive rectangle.
	 * When a card is selected the inactive rectangles must have a different color than the default one .*/
	inactiveProgressColor: PropTypes.string,
}

ProgressBar.defaultProps = {
	inactiveProgressColor: null,
}

export default ProgressBar
