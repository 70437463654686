import { cloneElement } from 'react'
import styled from '@xstyled/styled-components'

export const Root = styled(({ component, ...props }) => cloneElement(component, props))`
	width: 100%;
	${({ theme }) => ({ ...theme.fonts.formField })};
	border: medium;
	border-radius: regular;
	padding: 0 0.5rem;
	min-height: 2rem;
	background-color: backgroundSecondary;

	:hover,
	:focus,
	:focus-visible,
	:focus-within {
		outline-offset: 2px;
		outline: var(--focus) solid 2px;
	}

	div:nth-child(2) > div {
		flex-wrap: wrap;
		padding-right: 1rem;
	}
`
