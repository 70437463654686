import styled from '@xstyled/styled-components'

export const Root = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	row-gap: 1rem;

	> * {
		margin: 0;
	}
`
