const theme = {
	heights: {
		footer: '13.375rem',
		header: '11.5625rem',
		marker: '1.5rem',
		markerSelected: '2.5rem',
		groupedMarker: '2.5rem',
		projectDetailsModal: '78.43%',
		projectDetailsModalMaxHeight: '41.125rem',
	},
	widths: {
		filtersModal: '42.75rem',
		projectDetailsModal: '41.25%',
		projectDetailsModalMaxWidth: '28rem',
		marker: '1.5rem',
		markerSelected: '2.5rem',
		groupedMarker: '2.5rem',
	},
	colors: {
		backgroundPrimary: '#E6E5E6',
		backgroundSecondary: '#ffffff',
		backgroundMap: '#d4dadc',
		overlay: '#2626267a',
		background1: '#F5F5F5',
		progressTag: '#FFE7BA',
		primary1: '#ececfe',
		primary2: '#e3e3fd',
		primary3: '#cacafb',
		primary4: '#8585f6', // blue-france-625
		primary5: '#6a6af4', // blue-france-main-525
		primary6: '#000091', // blue-france-sun-113
		primary7: '#313178', // blue-france-200
		primary8: '#272747', // blue-france-125
		primary9: '#21213f', // blue-france-100
		primary10: '#1b1b35', // blue-france-75
		altPrimary1: '#FDEFF1',
		altPrimary2: '#FBDBDE',
		altPrimary3: '#F18992',
		altPrimary4: '#E73747', // blue-france-625
		altPrimary5: '#D2192A', // blue-france-main-525
		altPrimary6: '#A41421', // blue-france-sun-113
		altPrimary7: '#8E111C', // blue-france-200
		altPrimary8: '#770E18', // blue-france-125
		altPrimary9: '#600C13', // blue-france-100
		altPrimary10: '#49090F', // blue-france-75
		doneTag: '#D9F7BE',
		doneTagLabel: '#135200',
		divider: '#F0F0F0',
		grey1: '#d9d9d9',
		grey2: '#8C8C8C',
		grey3: '#595959',
		grey4: '#262626',
	},
	zIndices: {
		drawer: 500,
		exportButton: 500,
		loading: 1,
		mapController: 800,
		footer: 900,
		projectModal: 1000,
	},
	radii: {
		regular: '2px',
		medium: '4px',
		round: '50%',
	},
	borders: {
		light: '1px solid #F0F0F0',
		regular: '1px solid #d9d9d9',
		medium: '1px solid #8C8C8C',
		normal: '1px solid #E8E8E8',
		loaderInactive: '3px solid transparent',
		loaderActive: '3px solid #A41421',
		marker: '1px solid #ffffff',
		paletteSlot: '1px solid rgba(0, 0, 0, 0.2)',
	},
	shadows: {
		regular: 'inset 0px -1px 0px #d9d9d9',
		medium: '0px 2px 8px #0000003d',
		modal:
			'0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12)',
		controlButton: '0px 2px 8px #0000003d',
	},
	transitions: {
		width: 'width 0.2s ease',
	},
	fonts: {
		title: {
			fontFamily: 'Marianne',
			fontStyle: 'normal',
			fontWeight: 'bold',
			fontSize: 24,
			lineHeight: '32px',
		},
		semiTitle: {
			fontFamily: 'Marianne',
			fontStyle: 'normal',
			fontWeight: 'bold',
			fontSize: 20,
			lineHeight: '28px',
		},
		textTitle: {
			fontFamily: 'Marianne',
			fontStyle: 'normal',
			fontWeight: 'bold',
			fontSize: 16,
			lineHeight: '24px',
		},
		semiBold: {
			fontFamily: 'Marianne',
			fontStyle: 'normal',
			fontWeight: '700',
			fontSize: 16,
			lineHeight: '24px',
		},
		bold: {
			fontFamily: 'Marianne',
			fontStyle: 'normal',
			fontWeight: 'bold',
			fontSize: 14,
			lineHeight: '22px',
		},
		medium: {
			fontFamily: 'Marianne',
			fontStyle: 'normal',
			fontWeight: 'normal',
			fontSize: 14,
			lineHeight: '22px',
		},
		regular: {
			fontFamily: 'Marianne',
			fontStyle: 'normal',
			fontWeight: '700',
			fontSize: '12px',
			lineHeight: '20px',
			textAlign: 'center',
		},
		regularLight: {
			fontFamily: 'Marianne',
			fontStyle: 'normal',
			fontWeight: '400',
			fontSize: '12px',
			lineHeight: '20px',
		},
		regularBold: {
			fontFamily: 'Marianne',
			fontStyle: 'normal',
			fontWeight: '700',
			fontSize: '14px',
			lineHeight: '22px',
		},
		smallBold: {
			fontFamily: 'Marianne',
			fontStyle: 'normal',
			fontWeight: 'bold',
			fontSize: 12,
			lineHeight: '14px',
		},
		small: {
			fontFamily: 'Marianne',
			fontStyle: 'normal',
			fontWeight: '400',
			fontSize: 12,
			lineHeight: '20px',
		},
		formField: {
			fontFamily: 'Marianne',
			fontStyle: 'normal',
			fontWeight: '400',
			fontSize: 14,
			lineHeight: '22px',
			color: '#262626',
		},
	},
}

export default theme
