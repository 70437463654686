import { LOCAL } from 'shared/enums/storageTypes'

export const getFromStorageService = (key, type = LOCAL) => {
	try {
		const storage = type === 'local' ? localStorage : sessionStorage
		let value = storage.getItem(key)
		try {
			return JSON.parse(value)
		} catch (error) {
			return value
		}
	} catch (error) {
		throw error
	}
}

export const setToStorageService = (key, value, type = LOCAL) => {
	try {
		if (!key) {
			return false
		}
		const storage = type === LOCAL ? localStorage : sessionStorage
		if (value) {
			storage.setItem(key, JSON.stringify(value))
		} else {
			storage.removeItem(key)
		}
		return true
	} catch (error) {
		throw error
	}
}
