const routesConfig = {
	api: {
		route: `${window._env_.REACT_APP_BASE_URL}${window._env_.REACT_APP_API_ROUTE}`,
		children: {
			project: 'projects/{{projectId}}',
			projectsExport: 'projects/{{endpoint}}',
			projectExport: 'projects/{{projectId}}/{{endpoint}}',
			projects: {
				route: 'projects',
				children: {
					publishedByProjectId: 'published/{{projectId}}',
					published: 'published',
					exportsMap: 'exportsMap',
					exportsMapPng: 'exportsMapPng',
				},
			},
		},
	},
}

export default routesConfig
