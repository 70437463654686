export const importFileService = async (filePath) => {
	try {
		const file = await import(`../../assets/${filePath}`)
		return file.default
	} catch (error) {
		throw error
	}
}

export const downloadFileService = (
	content,
	fileName = Date.now().toString(),
	mimeType = 'application/octet-stream'
) => {
	try {
		const a = document.createElement('a')
		const blob = new Blob([content], {
			type: mimeType,
		})
		const url = window.URL.createObjectURL(blob)
		a.href = url
		a.download = fileName
		a.click()

		URL.revokeObjectURL(url)
	} catch (error) {
		throw error
	}
}
