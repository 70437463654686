import styled from '@xstyled/styled-components'

export const Root = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`

export const Loader = styled.div`
	border: loaderInactive;
	border-radius: 50%;
	border-top: loaderActive;
	width: 50px;
	height: 50px;
	-webkit-animation: spin 1.5s linear infinite; /* Safari */
	animation: spin 1.5s linear infinite;

	/* Safari */
	@-webkit-keyframes spin {
		0% {
			-webkit-transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
		}
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`
