import { Route, Router, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import React, { Suspense, lazy, useMemo } from 'react'
import getModuleWithConfig from '@ljn/auth-front'
import i18n from 'i18next'

import authRoutesConfig from 'shared/config/authRoutesConfig'

import { getHistory } from 'shared/utils/historyUtils'

import { selectIsAppReady } from 'modules/root/selectors/rootSelectors'

import Drawer from 'shared/components/layout/Drawer'
import ErrorBoundary from 'shared/components/error/ErrorBoundary'
import Footer from 'shared/components/layout/Footer'
import Header from 'shared/components/layout/Header'
import Loader from 'shared/components/loader/Loader'
import Main from 'shared/components/app/Main'
import NoMatchPage from 'shared/components/page/NoMatchPage'
import Page from 'shared/components/page/Page'
import ProdIsLoadingPage from 'shared/components/page/ProdIsLoadingPage'
import TabNav from 'shared/components/tabs/TabNav'

import * as Styled from 'shared/components/app/__styles__/App.styles'

const Cartography = lazy(() => import(/* webpackPrefetch: true */ 'modules/cartography'))
const Project = lazy(() => import(/* webpackPrefetch: true */ 'modules/project'))
const Projects = lazy(() => import(/* webpackPrefetch: true */ 'modules/projects'))
const Gdpr = lazy(() => import(/* webpackPrefetch: true */ 'modules/gdpr'))
const Accessibility = lazy(() => import(/* webpackPrefetch: true */ 'modules/accessibility'))
const Sitemap = lazy(() => import(/* webpackPrefetch: true */ 'modules/sitemap'))
const Legals = lazy(() => import(/* webpackPrefetch: true */ 'modules/legals'))

const { AuthModule, config: authConfig } = getModuleWithConfig({
	api: {
		route: `${window._env_.REACT_APP_BASE_URL}${window._env_.REACT_APP_API_ROUTE}`,
		children: {
			auth: {
				route: 'auth',
				children: {
					me: `${window._env_.REACT_APP_API_ROUTE}/profiles/me`,
				},
			},
			users: {
				route: 'users',
				children: {
					changePassword: '{{id}}/password',
				},
			},
		},
	},
	frontendRoutes: authRoutesConfig,
	guardedRoutes: 'none',
	persistencePolicy: {
		enabled: true,
		defaultChecked: true,
		duration: -1,
	},
	pageWrapper: function pageWrapper(config, children) {
		return (
			<Styled.Page>
				<Styled.Content role="main">
					<Styled.Logo link="/" />
					{children}
				</Styled.Content>
				<Footer />
			</Styled.Page>
		)
	},
	notificationWrapper: function notificationWrapper(config, notification) {
		return (
			<Styled.Notification key={notification.key} role="alert" variant={`alert ${notification.messageType}`} closable>
				{notification.message}
			</Styled.Notification>
		)
	},
	privateSubscriptionNotice: function privateSubscriptionNotice(config) {
		return (
			<Styled.Notice variant="highlight" size="sm">
				{i18n.t('root:auth.getAccountIntro')}
				<a href={`mailto:${i18n.t('root:auth.getAccountEmail')}`}>{i18n.t('root:auth.getAccountEmail')}</a>.
			</Styled.Notice>
		)
	},
	bruteforceNotice: function bruteforceNotice(config, message) {
		return (
			<Styled.Notice variant="highlight" size="sm">
				{message}
			</Styled.Notice>
		)
	},
	formWrapper: function formWrapper(config, children, isSubmitting, formErrors) {
		return (
			<>
				{formErrors && <Styled.Notice variant="alert error">{formErrors}</Styled.Notice>}
				{children}
			</>
		)
	},
	publicEmails: false,
	publicSubscription: false,
	emailValidation: false,
	t: i18n.getFixedT(null, ['auth']),
})

let authBundlesLoaded = false
const _initAuthI18n = () => {
	if (!authBundlesLoaded) {
		for (const [locale, resources] of Object.entries(authConfig.i18n)) {
			i18n.addResourceBundle(locale, 'auth', resources)
			authBundlesLoaded = true
		}
	}
}

const App = () => {
	_initAuthI18n()

	const isAppReady = useSelector(selectIsAppReady)

	const { t } = useTranslation('root')

	const tabs = useMemo(
		() => [
			{
				testId: 'cartography',
				label: t('root:tabs.cartography'),
				path: '/',
				component: <Cartography />,
			},
			{
				testId: 'projects',
				label: t('root:tabs.projects'),
				path: '/projets',
				component: <Projects />,
			},
		],
		[t]
	)

	return (
		<ErrorBoundary>
			{process.env.REACT_APP_PROD_IS_TESTING && JSON.parse(process.env.REACT_APP_PROD_IS_TESTING) ? (
				<ProdIsLoadingPage />
			) : isAppReady ? (
				<Router history={getHistory()}>
					<AuthModule>
						<Header>
							<TabNav tabs={tabs} defaultActiveTab={0} />
						</Header>
						<Main>
							<Drawer />
							<Switch>
								{tabs.map(({ component, label, path }, index) => (
									<Route key={`${label}_${index}`} exact path={path}>
										<Page>
											<Suspense fallback={<Loader ariaLabel={t('root:loader.inProgress')} />}>{component} </Suspense>
										</Page>
									</Route>
								))}
								<Route path={'/projets/:projectId'}>
									<Page>
										<Suspense fallback={<Loader ariaLabel={t('root:loader.inProgress')} />}>
											<Project />
										</Suspense>
									</Page>
								</Route>
								<Route path={'/plan-du-site'}>
									<Page>
										<Suspense fallback={<Loader ariaLabel={t('root:loader.inProgress')} />}>
											<Sitemap />
										</Suspense>
									</Page>
								</Route>
								<Route path={'/accessibilite'}>
									<Page>
										<Suspense fallback={<Loader ariaLabel={t('root:loader.inProgress')} />}>
											<Accessibility />
										</Suspense>
									</Page>
								</Route>
								<Route path={'/donnees-personnelles-et-cookies'}>
									<Page>
										<Suspense fallback={<Loader ariaLabel={t('root:loader.inProgress')} />}>
											<Gdpr />
										</Suspense>
									</Page>
								</Route>
								<Route path={'/mentions-legales'}>
									<Page>
										<Suspense fallback={<Loader ariaLabel={t('root:loader.inProgress')} />}>
											<Legals />
										</Suspense>
									</Page>
								</Route>
								<Route path="*">
									<NoMatchPage
										title={t('root:no-match.title')}
										message={t('root:no-match.message')}
										redirect={t('root:no-match.redirect')}
									/>
								</Route>
							</Switch>
						</Main>
						<Footer />
					</AuthModule>
				</Router>
			) : (
				<Loader ariaLabel={t('root:appLoader.inProgress')} />
			)}
		</ErrorBoundary>
	)
}

export default App
