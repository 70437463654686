import { get } from 'lodash'

export const selectIsAppReady = (state) => get(state, 'root.isAppReady', false)
export const selectColors = (state) => get(state, 'root.colors', [])
export const selectUserColors = (state) => get(state, 'root.userColors', {})
export const selectProjects = (state) => get(state, 'root.projects', null)
export const selectCheckedProjects = (state) => get(state, 'root.checkedProjects', null)
export const selectFilters = (state) => get(state, 'root.filters', null)
export const selectIsPostFiltersLoading = (state) => get(state, 'root.isPostFiltersLoading', false)
export const selectHoveredProject = (state) => get(state, 'root.hoveredProject', null)
