import '@gouvfr/dsfr/dist/component/logo/logo.min.css'
import { Link } from 'react-router-dom'
import { LinkInReactRouter } from '@ljn/reclame'
import { selectCurrentUser } from '@ljn/auth-front'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from '@ljn/prop-types'
import React from 'react'

import authRoutesConfig from 'shared/config/authRoutesConfig'

import * as Styled from 'shared/components/layout/__styles__/Header.styles'

const Header = ({ children }) => {
	const { t } = useTranslation('root')

	const currentUser = useSelector(selectCurrentUser)

	return (
		<Styled.Root role="banner" className="fr-header">
			<Styled.HeaderBody>
				<Styled.LogoContainer to={'/'}>
					<Styled.FrLogoContainer>
						<div className={'fr-logo'}>
							<span className={'rf-logo__title'}>{t('root:header.government')}</span>
						</div>
					</Styled.FrLogoContainer>
					<Styled.Image
						src={`${process.env.PUBLIC_URL}/static/images/service_logo.svg`}
						alt={t('root:header.serviceAlt')}
					/>
				</Styled.LogoContainer>
				<Styled.LoginContainer>
					<div className="fr-header__body">
						<div className="fr-container">
							<div className="fr-header__body-row">
								<div className="fr-header__tools">
									<div className="fr-header__tools-links">
										<ul className="fr-btns-group">
											{!!currentUser ? (
												<>
													<li>
														<Link
															className="fr-btn fr-icon-lock-unlock-line"
															to={authRoutesConfig.signOut}
															component={LinkInReactRouter}
														>
															{t('root:header.logout')}
														</Link>
													</li>
													<li>
														{window._env_.REACT_APP_BO_URL && (
															<a className="fr-btn fr-icon-external-link-fill" href={window._env_.REACT_APP_BO_URL}>
																{t('root:header.admin')}
															</a>
														)}
													</li>
												</>
											) : (
												<li>
													<Link
														className="fr-btn fr-icon-lock-line"
														to={authRoutesConfig.signIn}
														component={LinkInReactRouter}
													>
														{t('root:header.login')}
													</Link>
												</li>
											)}
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Styled.LoginContainer>
			</Styled.HeaderBody>
			<Styled.HeaderNav>{children}</Styled.HeaderNav>
		</Styled.Root>
	)
}

export default Header

Header.propTypes = {
	/** Define the content to the header HTML element. */
	children: PropTypes.node,
}
