import { Link } from 'react-router-dom'
import styled, { css } from '@xstyled/styled-components'

export const Root = styled.footer`
	min-height: ${({ theme }) => theme.heights.footer};
	a {
		background-image: none;
	}
	${({ $isEmbedded }) =>
		!$isEmbedded &&
		css`
			border-top-width: 2px;
			border-top-style: solid;
			border-top-color: primary6;
		`}
`
export const FooterBody = styled.div`
	padding: 1.25rem 1.5rem 1rem;
`

export const TopLayer = styled.div`
	min-height: 4.875rem;
	margin-bottom: 1.25rem;
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	justify-content: space-between;
`

export const LogoContainer = styled(Link)`
	display: flex;
	flex: 1;
	align-items: center;
	column-gap: 1.5rem;
	box-shadow: unset;
`

export const FrLogoContainer = styled.span`
	max-width: 8.6rem;
`

export const ExternalLinks = styled.div`
	display: flex;
	column-gap: 2.5rem;
	align-items: center;
	margin: 1.5rem 0;
	> a {
		${({ theme }) => ({ ...theme.fonts.bold })};
		color: grey4;
		box-shadow: none;
	}
	[target='_blank']:after {
		--icon-size: 0rem;
		content: '';
	}
`

export const BottomLayer = styled.div`
	min-height: 5rem;
	border-top-width: 1px;
	border-top-style: solid;
	border-top-color: grey1;
`

export const InnerLinks = styled.div`
	${({ theme }) => ({ ...theme.fonts.small })};
	margin: 1rem 0 1.5rem;
	> a {
		color: grey3;
		box-shadow: none;
	}

	> a + a {
		margin-left: 2rem;
		position: relative;
		:before {
			content: '';
			width: 1px;
			height: 1rem;
			margin-top: -0.5rem;
			position: absolute;
			top: 50%;
			left: -1rem;
			border-left-width: 1px;
			border-left-style: solid;
			border-left-color: grey1;
		}
	}
`

export const CopyrightNotice = styled.div`
	${({ theme }) => ({ ...theme.fonts.small })};
	color: grey3;
	[target='_blank']:after {
		--icon-size: 0rem;
		content: '';
		height: auto;
	}
`

export const FooterContent = styled.div`
	display: flex;
	flex-direction: column;
`
