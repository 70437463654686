import '@gouvfr/dsfr/dist/component/button/button.min.css'
import '@gouvfr/dsfr/dist/component/header/header.min.css'
import '@gouvfr/dsfr/dist/core/core.min.css'
import '@gouvfr/dsfr/dist/utility/utility.main.css'
import { Link } from 'react-router-dom'
import { Button as _Button } from '@ljn/reclame'
import styled from '@xstyled/styled-components'

export const Root = styled.header`
	a:not(.fr-btn) {
		box-shadow: none;
	}
`
export const HeaderBody = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 0 1.5rem;
	align-items: center;
	flex-wrap: wrap;
`

export const HeaderNav = styled.span`
	padding: 0 1.5rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: row;
`

export const LogoContainer = styled(Link)`
	display: flex;
	flex: 1;
	align-items: center;
	column-gap: 1.5rem;
	box-shadow: unset;
`

export const FrLogoContainer = styled.span`
	max-width: 8.6rem;
`

export const LoginContainer = styled.div`
	display: flex;
	column-gap: 2.5rem;
	align-items: center;
	> a {
		height: fit-content;
	}
`

export const Button = styled(_Button)`
	box-shadow: unset !important;
`

export const Image = styled.img`
	margin-top: 0.1rem;
`
