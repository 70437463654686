import PropTypes from '@ljn/prop-types'
import React, { useCallback } from 'react'

import * as Styled from 'shared/components/modal/__styles__/Dialog.styles'

const Dialog = ({ title, closeButtonAriaLabel, children, onClose, ...othersProps }) => {
	const _onClose = useCallback(() => onClose?.(), [onClose])

	return (
		<Styled.Root {...othersProps}>
			<Styled.Container>
				<Styled.Header>
					<Styled.Title>{title}</Styled.Title>
					<Styled.CloseButton onClick={_onClose} ariaLabel={closeButtonAriaLabel} icon="close-line" />
				</Styled.Header>
				{children}
			</Styled.Container>
		</Styled.Root>
	)
}

Dialog.propTypes = {
	title: PropTypes.string,
	closeButtonAriaLabel: PropTypes.string,
	children: PropTypes.node,
	/** Defines the callback called to close the popup. */
	onClose: PropTypes.func,
}

Dialog.defaultProps = {
	title: null,
	closeButtonAriaLabel: null,
	children: null,
	onClose: null,
}

export default Dialog
