import PropTypes from '@ljn/prop-types'
import React from 'react'

import * as Styled from 'shared/components/page/__styles__/ProdIsLoadingPage.styles'
import { useTranslation } from 'react-i18next'

const ProdIsLoadingPage = ({ children, ...props }) => {
	const { t } = useTranslation('root')

	return (
		<Styled.Root {...props}>
			<Styled.Container>
				<Styled.LogoContainer>
					<div className={'fr-logo'}>
						<span className={'rf-logo__title'}>{t('root:header.government')}</span>
					</div>
					<Styled.Operator>{t('root:header.operator')}</Styled.Operator>
				</Styled.LogoContainer>
				<Styled.Content>
					<p>
						Le site&nbsp;
						<a href="https://cartographie-projets.lutte-pauvrete.gouv.fr">
							cartographie-projets.lutte-pauvrete.gouv.fr
						</a>{' '}
						est en cours de recettage et sera ouvert dans quelques jours.
					</p>
					<p>Nous vous remercions pour votre attention.</p>
				</Styled.Content>
			</Styled.Container>
		</Styled.Root>
	)
}

ProdIsLoadingPage.propTypes = {
	children: PropTypes.node,
}

export default ProdIsLoadingPage
