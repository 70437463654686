export const SET_TO_STORAGE = 'storage/setToStorage'
export const SET_TO_STORAGE_SUCCESS = 'storage/setToStorageSuccess'
export const SET_TO_STORAGE_FAILURE = 'storage/setToStorageFailure'
export const GET_FROM_STORAGE = 'storage/getFromStorage'
export const GET_FROM_STORAGE_SUCCESS = 'storage/getFromStorageSuccess'
export const GET_FROM_STORAGE_FAILURE = 'storage/getFromStorageFailure'

export const setToStorage = (requestKey, requestBody, storageType, transactionId = null) => ({
	type: SET_TO_STORAGE,
	payload: {
		requestKey,
		requestBody,
		storageType,
		transactionId,
	},
})

export const setToStorageSuccess = (transactionId = null) => ({
	type: SET_TO_STORAGE_SUCCESS,
	payload: {
		transactionId,
	},
})

export const setToStorageFailure = (err, transactionId = null) => ({
	type: SET_TO_STORAGE_FAILURE,
	payload: {
		error: err,
		errorString: err && err.toString(),
		transactionId,
	},
})

export const getFromStorage = (requestKey, storageType, transactionId = null) => ({
	type: GET_FROM_STORAGE,
	payload: {
		requestKey,
		storageType,
		transactionId,
	},
})

export const getFromStorageSuccess = (responseBody, transactionId = null) => ({
	type: GET_FROM_STORAGE_SUCCESS,
	payload: {
		responseBody,
		transactionId,
	},
})

export const getFromStorageFailure = (err, transactionId = null) => ({
	type: GET_FROM_STORAGE_FAILURE,
	payload: {
		error: err,
		errorString: err && err.toString(),
		transactionId,
	},
})
