import '@gouvfr/dsfr/dist/component/logo/logo.min.css'
import { ExternalLink, LinkInReactRouter } from '@ljn/reclame'
import { Link } from 'react-router-dom'
import { Trans } from 'react-i18next'
import { useTranslation } from 'react-i18next'
import PropTypes from '@ljn/prop-types'
import React from 'react'

import * as Styled from 'shared/components/layout/__styles__/Footer.styles'

const Footer = ({ isEmbedded }) => {
	const { t } = useTranslation('root')

	return (
		<Styled.Root role="banner" className="fr-footer" $isEmbedded={isEmbedded}>
			<Styled.FooterBody>
				<Styled.TopLayer>
					<Styled.LogoContainer to={'/'}>
						<Styled.FrLogoContainer>
							<div className={'fr-logo'}>
								<span>{t('root:header.government')}</span>
							</div>
						</Styled.FrLogoContainer>
					</Styled.LogoContainer>
					<Styled.FooterContent>
						<Styled.ExternalLinks>
							<ExternalLink href="https://www.legifrance.gouv.fr/">legifrance.gouv.fr</ExternalLink>
							<ExternalLink href="https://www.service-public.fr/">service-public.fr</ExternalLink>
							<ExternalLink href="https://www.gouvernement.fr/">gouvernement.fr</ExternalLink>
							<ExternalLink href="https://www.france.fr/fr">france.fr</ExternalLink>
						</Styled.ExternalLinks>
					</Styled.FooterContent>
				</Styled.TopLayer>
				<Styled.BottomLayer>
					<Styled.InnerLinks>
						<Link component={LinkInReactRouter} to="/plan-du-site">
							{t('root:footer.sitemap')}
						</Link>
						<Link component={LinkInReactRouter} to="/accessibilite">
							{t('root:footer.accessibility')}
						</Link>
						<Link component={LinkInReactRouter} to="/mentions-legales">
							{t('root:footer.legal')}
						</Link>
						<Link component={LinkInReactRouter} to="/donnees-personnelles-et-cookies">
							{t('root:footer.dataprivacy')}
						</Link>
					</Styled.InnerLinks>
					<Styled.CopyrightNotice>
						<Trans
							i18nKey="root:footer.copyright"
							components={[<ExternalLink key="a" href="https://github.com/etalab/licence-ouverte/blob/master/LO.md" />]}
						/>
					</Styled.CopyrightNotice>
				</Styled.BottomLayer>
			</Styled.FooterBody>
		</Styled.Root>
	)
}

Footer.propTypes = {
	isEmbedded: PropTypes.bool,
}

Footer.defaultProps = {
	isEmbedded: false,
}

export default Footer
