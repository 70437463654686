import { Button as _Button } from '@ljn/reclame'
import Modal from 'react-modal'
import styled from '@xstyled/styled-components'

import 'react-datepicker/dist/react-datepicker.css'

export const Root = styled(Modal)``

export const Container = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 100%;
	height: 100%;
	max-height: 100vh;
	border-radius: regular;
	background-color: backgroundSecondary;
`

export const Header = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	padding: 1rem 1.5rem;
	border-bottom: light;
	align-items: center;
	position: relative;
`

export const Title = styled.h4`
	${({ theme }) => ({ ...theme.fonts.semiBold })};
	margin: 0;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
`

export const CloseButton = styled(_Button)`
	position: absolute;
	top: 1rem;
	right: 1rem;
	&& {
		background: none;
		padding: 0 !important;
		color: grey2;
		min-height: unset !important;
		max-height: unset !important;
		max-width: unset !important;
		--recl-icon-size: 1rem !important;
		height: 1rem;

		:hover,
		:active {
			background-image: unset !important;
			color: primary5 !important;
		}
	}
`
