import styled from '@xstyled/styled-components'

export const Root = styled.nav``

export const Container = styled.ul`
	display: flex;

	> * + * {
		margin-left: 2.5rem;
	}

	li {
		list-style-type: none;
	}
`
