export const STARTUP = 'root/startup'
export const STARTUP_SUCCESS = 'root/startupSuccess'
export const STARTUP_FAILURE = 'root/startupFailure'

export const FETCH_PUBLISHED_PROJECTS = 'root/fetchPublishedProjects'
export const FETCH_PUBLISHED_PROJECTS_SUCCESS = 'root/fetchPublishedProjectsSuccess'
export const FETCH_PUBLISHED_PROJECTS_FAILURE = 'root/fetchPublishedProjectsFailure'

export const FETCH_PUBLISHED_PROJECT = 'root/fetchPublishedProject'
export const FETCH_PUBLISHED_PROJECT_SUCCESS = 'root/fetchPublishedProjectSuccess'
export const FETCH_PUBLISHED_PROJECT_FAILURE = 'root/fetchPublishedProjectFailure'

export const TOGGLE_CHECKED_PROJECTS = 'root/toggleCheckedProjects'
export const TOGGLE_CHECKED_PROJECTS_SUCCESS = 'root/toggleCheckedProjectsSuccess'
export const TOGGLE_CHECKED_PROJECTS_FAILURE = 'root/toggleCheckedProjectsFailure'

export const POST_FILTERS = 'root/postFilters'
export const POST_FILTERS_SUCCESS = 'root/postFilterSuccess'
export const POST_FILTERS_FAILURE = 'root/postFilterFailure'

export const STORE_COLORS = 'root/storeColors'
export const STORE_COLORS_SUCCESS = 'root/storeColorsSuccess'
export const STORE_COLORS_FAILURE = 'root/storeColorsFailure'

export const RETRIEVE_COLORS = 'root/retrieveColors'
export const RETRIEVE_COLORS_SUCCESS = 'root/retrieveColorsSuccess'
export const RETRIEVE_COLORS_FAILURE = 'root/retrieveColorsFailure'

export const SET_HOVERED_PROJECT = 'root/setHoveredProject'
export const SET_HOVERED_PROJECT_SUCCESS = 'root/setHoveredProjectSuccess'
export const SET_HOVERED_PROJECT_FAILURE = 'root/setHoveredProjectFailure'

export const STORE_USER_COLORS = 'root/storeUserColors'
export const STORE_USER_COLORS_SUCCESS = 'root/storeUserColorsSuccess'
export const STORE_USER_COLORS_FAILURE = 'root/storeUserColorsFailure'

export const RETRIEVE_USER_COLORS = 'root/retrieveUserColors'
export const RETRIEVE_USER_COLORS_SUCCESS = 'root/retrieveUserColorsSuccess'
export const RETRIEVE_USER_COLORS_FAILURE = 'root/retrieveUserColorsFailure'

export const startup = () => {
	return {
		type: STARTUP,
	}
}

export const startupSuccess = () => {
	return {
		type: STARTUP_SUCCESS,
	}
}

export const startupFailure = (error) => {
	return {
		type: STARTUP_FAILURE,
		payload: {
			error: error,
			errorString: error?.toString(),
		},
	}
}

export const toggleCheckedProjects = (projectIds, forceAll) => {
	return {
		type: TOGGLE_CHECKED_PROJECTS,
		payload: {
			projectIds,
			forceAll,
		},
	}
}

export const toggleCheckedProjectsSuccess = (projectIds) => {
	return {
		type: TOGGLE_CHECKED_PROJECTS_SUCCESS,
		payload: {
			projectIds,
		},
	}
}

export const toggleCheckProjectsFailure = (error) => {
	return {
		type: TOGGLE_CHECKED_PROJECTS_FAILURE,
		payload: {
			error: error,
			errorString: error?.toString(),
		},
	}
}

export const fetchPublishedProjects = (index, limit, sort, filters) => {
	return {
		type: FETCH_PUBLISHED_PROJECTS,
		payload: {
			index,
			limit,
			sort,
			filters,
		},
	}
}

export const fetchPublishedProjectsSuccess = (projects) => {
	return {
		type: FETCH_PUBLISHED_PROJECTS_SUCCESS,
		payload: {
			projects,
		},
	}
}

export const fetchPublishedProjectsFailure = (error) => {
	return {
		type: FETCH_PUBLISHED_PROJECTS_FAILURE,
		payload: {
			error: error,
			errorString: error?.toString(),
		},
	}
}

export const fetchPublishedProject = (projectId) => {
	return {
		type: FETCH_PUBLISHED_PROJECT,
		payload: {
			projectId,
		},
	}
}

export const fetchPublishedProjectSuccess = (project) => {
	return {
		type: FETCH_PUBLISHED_PROJECT_SUCCESS,
		payload: {
			project,
		},
	}
}

export const fetchPublishedProjectFailure = (error) => {
	return {
		type: FETCH_PUBLISHED_PROJECT_FAILURE,
		payload: {
			error: error,
			errorString: error?.toString(),
		},
	}
}

export const setHoveredProject = (projectId) => {
	return {
		type: SET_HOVERED_PROJECT,
		payload: {
			projectId,
		},
	}
}

export const setHoveredProjectSuccess = (project) => {
	return {
		type: SET_HOVERED_PROJECT_SUCCESS,
		payload: {
			project,
		},
	}
}

export const setHoveredProjectFailure = (error) => {
	return {
		type: SET_HOVERED_PROJECT_FAILURE,
		payload: {
			error: error,
			errorString: error?.toString(),
		},
	}
}

export const postFilters = (filters) => {
	return {
		type: POST_FILTERS,
		payload: {
			filters,
		},
	}
}

export const postFiltersSuccess = (filters) => {
	return {
		type: POST_FILTERS_SUCCESS,
		payload: {
			filters,
		},
	}
}

export const postFiltersFailure = (error) => {
	return {
		type: POST_FILTERS_FAILURE,
		payload: {
			error: error,
			errorString: error?.toString(),
		},
	}
}

export const storeColors = (colors) => {
	return {
		type: STORE_COLORS,
		payload: {
			colors,
		},
	}
}

export const storeColorsSuccess = () => {
	return {
		type: STORE_COLORS_SUCCESS,
	}
}

export const storeColorsFailure = (error) => {
	return {
		type: STORE_COLORS_FAILURE,
		payload: {
			error: error,
			errorString: error?.toString(),
		},
	}
}

export const retrieveColors = () => {
	return {
		type: RETRIEVE_COLORS,
	}
}

export const retrieveColorsSuccess = (colors) => {
	return {
		type: RETRIEVE_COLORS_SUCCESS,
		payload: {
			colors,
		},
	}
}

export const retrieveColorsFailure = (error) => {
	return {
		type: RETRIEVE_COLORS_FAILURE,
		payload: {
			error: error,
			errorString: error?.toString(),
		},
	}
}

export const storeUserColors = (colors) => {
	return {
		type: STORE_USER_COLORS,
		payload: {
			colors,
		},
	}
}

export const storeUserColorsSuccess = () => {
	return {
		type: STORE_USER_COLORS_SUCCESS,
	}
}

export const storeUserColorsFailure = (error) => {
	return {
		type: STORE_USER_COLORS_FAILURE,
		payload: {
			error: error,
			errorString: error?.toString(),
		},
	}
}

export const retrieveUserColors = () => {
	return {
		type: RETRIEVE_USER_COLORS,
	}
}

export const retrieveUserColorsSuccess = (colors) => {
	return {
		type: RETRIEVE_USER_COLORS_SUCCESS,
		payload: {
			colors,
		},
	}
}

export const retrieveUserColorsFailure = (error) => {
	return {
		type: RETRIEVE_USER_COLORS_FAILURE,
		payload: {
			error: error,
			errorString: error?.toString(),
		},
	}
}
