import PropTypes from '@ljn/prop-types'
import React from 'react'

import * as Styled from 'shared/components/button/__styles__/PaginationButtonContainer.styles'

const PaginationButtonContainer = ({ showButton, children }) =>
	showButton ? <Styled.Root>{children}</Styled.Root> : null

export default PaginationButtonContainer

PaginationButtonContainer.propTypes = {
	/** Defines if the button must be show. */
	showButton: PropTypes.bool,
	children: PropTypes.node,
}
PaginationButtonContainer.defaultProps = {
	showButton: true,
}
