import styled, { css } from '@xstyled/styled-components'

// HACK: the main area size is the screen height - the header and the collapsed
// version of the footer, so that the cartography page has a min-height enabling
// Leaflet to take up the whole available space. The 3rem comes from the height
// of the Reclame Collapser control. The 2px comes from the DIPLP borders hardcoded
// onto the Collapser used in FooterCollapser. This code is as brittle as it looks.

export const Root = styled.main`
	display: flex;
	align-content: stretch;
	position: relative;
	${({ $fixedHeight, theme }) =>
		css`
			${$fixedHeight ? 'height' : 'min-height'}: calc(100vh - ${theme.heights.header} - 3rem - 2px);
		`}
`
