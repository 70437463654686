import { Button } from '@ljn/reclame'
import { isNil } from '@ljn/utils'
import { useTheme } from '@xstyled/styled-components'
import { useTranslation } from 'react-i18next'
import PropTypes from '@ljn/prop-types'

import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { initialState } from 'modules/root/reducers/rootReducer'

import { MAX_BUDGET, MIN_BUDGET, fundingScope } from 'shared/enums/projectFilterTypes'
import { TOP } from 'shared/enums/selectTypes'
import projectTypes, { BEING_DEFINED, DEFERRED_LAUNCH, PROJECT_DONE, RUNNING } from 'shared/enums/projectTypes'

import { formatPercent, formatToPartsLinear } from 'shared/utils/numberUtils'

import AutoComplete from 'shared/components/autoComplete/AutoComplete'
import Loader from 'shared/components/loader/Loader'
import Select from 'shared/components/select/Select'

import locationSource from 'shared/components/autoComplete/sources/locationSource'
import targetedAudienceSource from 'shared/components/autoComplete/sources/targetedAudienceSource'

import * as Styled from 'shared/components/modal/__styles__/FiltersContent.styles'

const getPercentageGradient = (value, max) => formatPercent(value / max, true)

const FiltersContent = ({
	filters,
	isPostFiltersLoading,
	onChange,
	onChangeControlled,
	onSubmit,
	onSliderChange,
	onInputChange,
	onMultiSelectChange,
	onClose,
	onClean,
	isLogged,
}) => {
	const [isLoading, setIsLoading] = useState(false)

	const { t } = useTranslation('root')

	const theme = useTheme()

	useEffect(() => {
		if (!isPostFiltersLoading && isLoading) {
			onClose?.()
		}
	}, [isPostFiltersLoading, isLoading, onClose])

	const fundingScopeOptions = useMemo(
		() =>
			fundingScope.map((option) => ({
				value: t(`root:modal.form.fundingScopeOptions.${option}`),
				label: t(`root:modal.form.fundingScopeOptions.${option}`),
			})),
		[t]
	)

	const fundingScopeValue = useMemo(() => {
		// eslint-disable-next-line react/prop-types
		return filters.fundingScope?.map((value) => ({ value, label: value }))
	}, [filters])

	const targetedAudienceValue = useMemo(() => {
		// eslint-disable-next-line react/prop-types
		return filters.targetedAudience?.map((value) => ({ value, label: value }))
	}, [filters])

	const deploymentAreaValue = useMemo(() => {
		// eslint-disable-next-line react/prop-types
		return filters.deploymentArea?.map((value) => ({ value, label: value }))
	}, [filters])

	const _onSliderClick = (isActive, value, callback) => () => callback?.(isActive ? value : null)

	const renderSliderField = useCallback(
		(type, min, max, value, formatCurrency = false, onChange) => {
			const newValue = isNil(value) ? 0 : parseInt(value)
			const onClick = isNil(value) ? _onSliderClick(true, newValue, onChange) : null
			return (
				<Styled.SliderField onClick={onClick}>
					<Styled.FieldLabel htmlFor={type}>{t(`root:modal.form.${type}`)}</Styled.FieldLabel>
					<Styled.FieldDescription>{t(`root:modal.form.${type}Description`)}</Styled.FieldDescription>
					<Styled.SliderContent>
						<Styled.Slider
							disabled={isNil(value)}
							type="range"
							id={type}
							min={min}
							max={max}
							value={newValue}
							step="1"
							name={type}
							aria-valuemin={min}
							aria-valuemax={max}
							aria-valuenow={newValue}
							onChange={onSliderChange?.(onChange)}
							style={{
								background: `linear-gradient( to right, ${theme.colors.primary5} ${getPercentageGradient(
									newValue,
									max
								)}, ${theme.colors.background1} ${getPercentageGradient(newValue, max)} )`,
							}}
						/>
						{!isNil(value) && (
							<Styled.IconButton
								onClick={_onSliderClick(false, newValue, onChange)}
								ariaLabel={t('root:modal.ariaLabelButtonClose')}
								icon="close-circle-line"
							/>
						)}
					</Styled.SliderContent>
					<Styled.SliderLabels disabled={isNil(value)}>
						<Styled.SliderLabel>{formatToPartsLinear(min, formatCurrency)}</Styled.SliderLabel>
						<Styled.SliderLabel>{formatToPartsLinear((min + max) / 2, formatCurrency)}</Styled.SliderLabel>
						<Styled.SliderLabel>{formatToPartsLinear(max, formatCurrency)}</Styled.SliderLabel>
					</Styled.SliderLabels>
				</Styled.SliderField>
			)
		},
		[onSliderChange, theme, t]
	)

	const renderInputField = useCallback(
		(type, value, onChange) => {
			const newValue = isNil(value) || !value ? '' : parseInt(value)
			return (
				<Styled.SelectTagContainer>
					<Styled.FieldLabelWithMargin htmlFor={type}>{t(`root:modal.form.${type}`)}</Styled.FieldLabelWithMargin>
					<Styled.Input value={newValue} onChange={onInputChange(onChange)} />
				</Styled.SelectTagContainer>
			)
		},
		[onInputChange, t]
	)

	const _onCheck = useCallback(
		(type, callback) => (e) => {
			let projectProgressionValues = filters.projectProgression || initialState.filters.projectProgression
			if (type === 'done') {
				projectProgressionValues = e.target.checked
					? [...projectProgressionValues, PROJECT_DONE]
					: projectProgressionValues.filter((progression) => progression !== PROJECT_DONE)
			} else {
				projectProgressionValues = e.target.checked
					? [...projectProgressionValues, BEING_DEFINED, RUNNING, DEFERRED_LAUNCH]
					: projectProgressionValues.filter((progression) => progression === PROJECT_DONE)
			}
			return callback(projectProgressionValues)
		},
		[filters]
	)

	const renderCheckBox = useCallback(
		(type, checked, onCheck) => (
			<>
				<Styled.Checkbox forceChecked={checked} aria-describedby={type} name={type} onCheck={_onCheck(type, onCheck)}>
					{t(`root:tag.${type}`)}
				</Styled.Checkbox>
				<p className="recl-hidden" id={type}>
					{t(`root:modal.form.${type}Info`)}
				</p>
			</>
		),
		[t, _onCheck]
	)

	const _onDatePickerChange = (dates) => {
		const [startDate, endDate] = dates
		onChange?.('startDate')(startDate)
		onChange?.('endDate')(endDate)
	}

	const _onSubmit = useCallback(
		(e) => {
			e.preventDefault()
			onSubmit?.(filters)
			setIsLoading(true)
		},
		[onSubmit, filters]
	)

	const _onClean = useCallback(() => {
		onClean?.()
		setIsLoading(true)
	}, [onClean])

	return (
		<Styled.Root>
			{isPostFiltersLoading && (
				<Styled.LoadingContainer>
					<Loader ariaLabel={t('root:drawer.form.postFilterLoadingAriaLabel')} />
				</Styled.LoadingContainer>
			)}
			<Styled.Form onSubmit={_onSubmit}>
				<Styled.Content>
					<Styled.FirstFieldTitle>{t('root:modal.form.progress')}</Styled.FirstFieldTitle>
					<Styled.Row>
						{renderCheckBox(
							'inProgress',
							filters.projectProgression?.includes(BEING_DEFINED) &&
								filters.projectProgression?.includes(RUNNING) &&
								filters.projectProgression?.includes(DEFERRED_LAUNCH),
							onChange?.('projectProgression')
						)}
						{renderCheckBox(
							'done',
							filters.projectProgression?.includes(PROJECT_DONE),
							onChange?.('projectProgression')
						)}
					</Styled.Row>
					<Styled.Field>
						<Styled.FieldLabelWithMargin htmlFor="date">{t('root:modal.form.startDate')}</Styled.FieldLabelWithMargin>
						<Styled.DatePicker
							selectsRange={true}
							dateFormat="dd/MM/yyyy"
							startDate={filters.startDate}
							endDate={filters.endDate}
							onChange={_onDatePickerChange}
							isClearable={true}
							locale="fr"
							placeholderText={t('root:modal.form.datePickerPlaceHolder')}
							calendarContainer={Styled.Calendar}
						/>
					</Styled.Field>
					<Styled.SecondFieldTitle>{t('root:modal.form.impactAndAudience')}</Styled.SecondFieldTitle>
					<Styled.RowFullSize>
						<Styled.SelectTagContainer>
							<Styled.FieldLabelWithMargin htmlFor="targetedAudience">
								{t('root:modal.form.targetedAudience')}
							</Styled.FieldLabelWithMargin>
							<AutoComplete
								name="targetedAudience"
								source={targetedAudienceSource}
								value={targetedAudienceValue}
								isMulti
								noOptionsMessage={t('root:autoComplete.noOptions')}
								loadingMessage={t('root:autoComplete.loading')}
								onChange={onMultiSelectChange?.(onChange?.('targetedAudience'))}
							/>
						</Styled.SelectTagContainer>
						<Styled.SelectTagContainer>
							<Styled.FieldLabelWithMargin htmlFor="deploymentArea">
								{t('root:modal.form.deploymentArea')}
							</Styled.FieldLabelWithMargin>
							<AutoComplete
								name="deploymentArea"
								source={locationSource}
								sourceParser={(data) =>
									data.map((item) => ({
										...item,
										options: item.options.map((option) => ({ ...option, value: option.code })),
									}))
								}
								value={deploymentAreaValue}
								isMulti
								noOptionsMessage={t('root:autoComplete.noOptions')}
								loadingMessage={t('root:autoComplete.loading')}
								onChange={onMultiSelectChange?.(onChange?.('deploymentArea'))}
							/>
						</Styled.SelectTagContainer>
					</Styled.RowFullSize>
					<Styled.Space>
						<Styled.RowFullSize>
							<Styled.FieldLabel htmlFor="beneficiaries">{t(`root:modal.form.beneficiaries`)}</Styled.FieldLabel>
							<Styled.FieldDescription>{t(`root:modal.form.beneficiariesDescription`)}</Styled.FieldDescription>
							{renderInputField('minBeneficiaries', filters.minBeneficiaries, onChangeControlled?.('minBeneficiaries'))}
							{renderInputField('maxBeneficiaries', filters.maxBeneficiaries, onChangeControlled?.('maxBeneficiaries'))}
						</Styled.RowFullSize>
						{isLogged &&
							renderSliderField('budget', MIN_BUDGET, MAX_BUDGET, filters.maxBudget, true, onChange?.('maxBudget'))}
						<Styled.Field>
							<Styled.FieldLabelWithMargin htmlFor="fundingScope">
								{t('root:modal.form.fundingScope')}
							</Styled.FieldLabelWithMargin>
							<Select
								options={fundingScopeOptions}
								menuPlacement={TOP}
								value={fundingScopeValue}
								isMulti
								onChange={onMultiSelectChange?.(onChange?.('fundingScope'))}
							/>
						</Styled.Field>
					</Styled.Space>
				</Styled.Content>
				<Styled.Footer>
					<Styled.ButtonDefault variant="secondary" onClick={_onClean} isDisabled={isPostFiltersLoading}>
						{t('root:modal.form.clean')}
					</Styled.ButtonDefault>
					<Button type="submit" onClick={() => null} isDisabled={isPostFiltersLoading}>
						{t('root:modal.form.submit')}
					</Button>
				</Styled.Footer>
			</Styled.Form>
		</Styled.Root>
	)
}

FiltersContent.propTypes = {
	isLogged: PropTypes.bool,
	/** Defines the filters to display and to use in the fields. */
	filters: PropTypes.shape({
		projectProgression: PropTypes.arrayOf(PropTypes.oneOfEnum(projectTypes)),
		deploymentArea: PropTypes.arrayOf(PropTypes.string),
		targetedAudience: PropTypes.arrayOf(PropTypes.string),
		startDate: PropTypes.string,
		endDate: PropTypes.string,
		maxBudget: PropTypes.number,
		minBeneficiaries: PropTypes.number,
		maxBeneficiaries: PropTypes.number,
		fundingScope: PropTypes.oneOfEnum(fundingScope),
	}),
	isPostFiltersLoading: PropTypes.bool,
	/** Defines the callback called when the form change, it's called on each field. */
	onChange: PropTypes.func,
	/** Defines the callback with number type control. Called when the form change on min and max beneficiary fields. */
	onChangeControlled: PropTypes.func,
	/** Defines the callback called to close submit the form. */
	onSubmit: PropTypes.func,
	/** Defines the callback called when a slider field change. */
	onSliderChange: PropTypes.func,
	/**Defines the callback called when a input field change. */
	onInputChange: PropTypes.func,
	/** Defines the callback called when a single select field change. */
	onSingleSelectChange: PropTypes.func,
	/** Defines the callback called when a multi select field change. */
	onMultiSelectChange: PropTypes.func,
	/** Defines the callback called to close the popup. */
	onClose: PropTypes.func,
	/** Defines the callback called the clean button is clicked. */
	onClean: PropTypes.func,
}

FiltersContent.defaultProps = {
	isLogged: false,
	filters: initialState.filters,
	isPostFiltersLoading: false,
	onChange: null,
	onChangeControlled: null,
	onSubmit: null,
	onSliderChange: null,
	onInputChange: null,
	onSingleSelectChange: null,
	onMultiSelectChange: null,
	onClose: null,
	onClean: null,
}

export default FiltersContent
