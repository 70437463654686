import { isEmpty } from 'lodash'
import { useLocation } from 'react-router'
import PropTypes from '@ljn/prop-types'
import React, { useCallback, useEffect, useState } from 'react'

import TabItem from 'shared/components/tabs/TabItem'

import * as Styled from 'shared/components/tabs/__styles__/TabNav.styles'

const TabNav = ({ tabs, defaultActiveTab }) => {
	const location = useLocation()

	const [activeTab, setActiveTab] = useState(defaultActiveTab)

	useEffect(() => {
		const pathname = location.pathname
		if (!isEmpty(tabs)) {
			setActiveTab(tabs.findIndex(({ path }) => path === pathname))
		}
	}, [location, tabs])

	/**
	 * Behavior from WAI-ARIA Authoring Practices 1.2.
	 * Https://www.w3.org/TR/wai-aria-practices/#keyboard-interaction-19.
	 */
	const onKeyDownTab = useCallback(
		(e, index) => {
			switch (e.key) {
				case 'ArrowRight':
					e.preventDefault()
					setActiveTab((index + 1) % tabs.length)
					break

				case 'ArrowLeft':
					e.preventDefault()
					setActiveTab(index - 1 < 0 ? tabs.length - 1 : index - 1)
					break

				case 'Home':
					e.preventDefault()
					setActiveTab(0)
					break

				case 'End':
					e.preventDefault()
					setActiveTab(tabs.length - 1)
					break

				default:
			}
		},
		[tabs]
	)

	return (
		<Styled.Root>
			<Styled.Container>
				{!isEmpty(tabs) &&
					tabs.map(({ label, path }, index) => (
						<TabItem
							key={`${label}_${index}`}
							activeTab={activeTab}
							onKeyDownTab={onKeyDownTab}
							index={index}
							label={label}
							path={path}
						/>
					))}
			</Styled.Container>
		</Styled.Root>
	)
}

TabNav.defaultProps = {
	defaultActiveTab: 0,
	tabs: [],
}

TabNav.propTypes = {
	/** Allows you to define the active tab.
	 * Used by the TabItem component to define whether it should be selected or not. */
	defaultActiveTab: PropTypes.number,
	/** The tabs that should be displayed.
	 * Each tab in this table becomes TabItem components. */
	tabs: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			path: PropTypes.string.isRequired,
		})
	),
}

export default TabNav
