import { call, fork, put, race, select, take, takeLatest } from 'redux-saga/effects'
import { makeApiUrlResolver } from '@ljn/utils'

import { FETCH_BUNDLE_SUCCESS, fetchBundle } from 'shared/actions/i18nActions'
import {
	FETCH_PUBLISHED_PROJECT,
	FETCH_PUBLISHED_PROJECTS,
	FETCH_PUBLISHED_PROJECTS_SUCCESS,
	POST_FILTERS,
	RETRIEVE_COLORS,
	RETRIEVE_COLORS_SUCCESS,
	RETRIEVE_USER_COLORS,
	RETRIEVE_USER_COLORS_SUCCESS,
	STARTUP,
	STORE_COLORS,
	STORE_USER_COLORS,
	TOGGLE_CHECKED_PROJECTS,
	fetchPublishedProjectFailure,
	fetchPublishedProjectSuccess,
	fetchPublishedProjects,
	fetchPublishedProjectsFailure,
	fetchPublishedProjectsSuccess,
	postFiltersFailure,
	postFiltersSuccess,
	retrieveColors,
	retrieveColorsFailure,
	retrieveColorsSuccess,
	retrieveUserColors,
	retrieveUserColorsFailure,
	retrieveUserColorsSuccess,
	startupFailure,
	startupSuccess,
	storeColorsFailure,
	storeColorsSuccess,
	storeUserColorsFailure,
	storeUserColorsSuccess,
	toggleCheckProjectsFailure,
	toggleCheckedProjectsSuccess,
} from 'modules/root/actions/rootActions'
import {
	GET_FROM_STORAGE_SUCCESS,
	SET_TO_STORAGE_SUCCESS,
	getFromStorage,
	setToStorage,
} from 'shared/actions/storageActions'
import { REQUEST_FAILURE, REQUEST_SUCCESS, request } from 'shared/actions/apiActions'

import { mapFiltersService, updateCheckedProjectsService } from 'modules/root/services/rootServices'

import config from 'shared/config/routesConfig'
import defaultColors from 'shared/config/colorsConfig'

import { PROJECTS_SIZE } from 'shared/enums/paginationsTypes'

import { selectCheckedProjects } from 'modules/root/selectors/rootSelectors'

export function* watchStartup() {
	yield takeLatest(STARTUP, startupSaga)
}

export function* startupSaga() {
	try {
		yield put(fetchBundle('root'))
		yield take(({ type, payload }) => type === FETCH_BUNDLE_SUCCESS && payload.namespace === 'root')

		yield put(retrieveColors())
		yield take(RETRIEVE_COLORS_SUCCESS)

		yield put(retrieveUserColors())
		yield take(RETRIEVE_USER_COLORS_SUCCESS)

		yield put(startupSuccess())
	} catch (err) {
		yield put(startupFailure(err))
	}
}

export function* watchFetchPublishedProjects() {
	yield takeLatest(FETCH_PUBLISHED_PROJECTS, fetchPublishedProjectsSaga)
}

export function* fetchPublishedProjectsSaga({ payload: { index, limit, sort, filters } }) {
	try {
		const transactionId = 'published_projects_fetch'

		const resolveApiUrl = yield call(makeApiUrlResolver, config)

		const url = yield call(resolveApiUrl, 'projects.published')

		const requestFilters = yield call(mapFiltersService, filters)

		const options = {
			method: 'POST',
			body: {
				limit,
				index,
				...(sort ? { sort } : {}),
				...requestFilters,
			},
		}

		yield put(request(url, options, transactionId))
		const { success, failure } = yield race({
			success: take(({ type, payload }) => type === REQUEST_SUCCESS && payload.transactionId === transactionId),
			failure: take(({ type, payload }) => type === REQUEST_FAILURE && payload.transactionId === transactionId),
		})

		if (!!success) {
			yield put(fetchPublishedProjectsSuccess(success.payload.responseBody))
		} else {
			throw new Error(failure.payload.error)
		}
	} catch (err) {
		yield put(fetchPublishedProjectsFailure(err))
	}
}

export function* watchFetchPublishedProject() {
	yield takeLatest(FETCH_PUBLISHED_PROJECT, fetchPublishedProjectSaga)
}

export function* fetchPublishedProjectSaga({ payload: { projectId } }) {
	try {
		const transactionId = 'published_project_fetch'

		const resolveApiUrl = yield call(makeApiUrlResolver, config)

		const url = yield call(resolveApiUrl, 'projects.publishedByProjectId', { projectId })

		yield put(request(url, null, transactionId))
		const { success, failure } = yield race({
			success: take(({ type, payload }) => type === REQUEST_SUCCESS && payload.transactionId === transactionId),
			failure: take(({ type, payload }) => type === REQUEST_FAILURE && payload.transactionId === transactionId),
		})

		if (!!success) {
			yield put(fetchPublishedProjectSuccess(success.payload.responseBody))
		} else {
			throw new Error(failure.payload.error)
		}
	} catch (err) {
		yield put(fetchPublishedProjectFailure(err))
	}
}

export function* watchPostFilters() {
	yield takeLatest(POST_FILTERS, postFiltersSaga)
}

export function* postFiltersSaga({ payload: { filters } }) {
	try {
		yield put(fetchPublishedProjects(0, PROJECTS_SIZE, null, filters))
		yield take(FETCH_PUBLISHED_PROJECTS_SUCCESS)

		yield put(postFiltersSuccess(filters))
	} catch (err) {
		yield put(postFiltersFailure(err))
	}
}

export function* watchToggleCheckedProjects() {
	yield takeLatest(TOGGLE_CHECKED_PROJECTS, toggleCheckedProjectsSaga)
}

export function* toggleCheckedProjectsSaga({ payload }) {
	try {
		const { projectIds, forceAll } = payload

		const oldProjectIds = yield select(selectCheckedProjects)

		const newProjectIds = yield call(updateCheckedProjectsService, oldProjectIds, projectIds, forceAll)

		yield put(toggleCheckedProjectsSuccess(newProjectIds))
	} catch (err) {
		yield put(toggleCheckProjectsFailure(err))
	}
}

export function* watchStoreColors() {
	yield takeLatest(STORE_COLORS, storeColorsSaga)
}

export function* storeColorsSaga({ payload: { colors } }) {
	try {
		yield put(setToStorage('colors', colors))
		yield take(SET_TO_STORAGE_SUCCESS)

		yield put(storeColorsSuccess())
	} catch (err) {
		yield put(storeColorsFailure(err))
	}
}

export function* watchRetrieveColors() {
	yield takeLatest(RETRIEVE_COLORS, retrieveColorsSaga)
}

export function* retrieveColorsSaga() {
	try {
		yield put(getFromStorage('colors'))
		const {
			payload: { responseBody },
		} = yield take(GET_FROM_STORAGE_SUCCESS)

		yield put(retrieveColorsSuccess(responseBody || defaultColors))
	} catch (err) {
		yield put(retrieveColorsFailure(err))
	}
}

export function* watchStoreUserColors() {
	yield takeLatest(STORE_USER_COLORS, storeUserColorsSaga)
}

export function* storeUserColorsSaga({ payload: { colors } }) {
	try {
		yield put(setToStorage('userColors', colors))
		yield take(SET_TO_STORAGE_SUCCESS)

		yield put(storeUserColorsSuccess())
	} catch (err) {
		yield put(storeUserColorsFailure(err))
	}
}

export function* watchRetrieveUserColors() {
	yield takeLatest(RETRIEVE_USER_COLORS, retrieveUserColorsSaga)
}

export function* retrieveUserColorsSaga() {
	try {
		yield put(getFromStorage('userColors'))
		const {
			payload: { responseBody },
		} = yield take(GET_FROM_STORAGE_SUCCESS)

		yield put(retrieveUserColorsSuccess(responseBody || {}))
	} catch (err) {
		yield put(retrieveUserColorsFailure(err))
	}
}

export default function* rootSaga() {
	yield fork(watchStartup)
	yield fork(watchFetchPublishedProjects)
	yield fork(watchFetchPublishedProject)
	yield fork(watchPostFilters)
	yield fork(watchStoreColors)
	yield fork(watchRetrieveColors)
	yield fork(watchStoreUserColors)
	yield fork(watchRetrieveUserColors)
	yield fork(watchToggleCheckedProjects)
}
