import { useLocation } from 'react-router-dom'
import PropTypes from '@ljn/prop-types'
import React from 'react'

import * as Styled from 'shared/components/app/__styles__/Main.styles'

const Main = ({ children }) => {
	const location = useLocation()

	return (
		<Styled.Root role="main" $fixedHeight={location.pathname === '/'}>
			{children}
		</Styled.Root>
	)
}

export default Main

Main.propTypes = {
	/** Define the content to the main HTML element. */
	children: PropTypes.node,
}
