import { ProgressWrapper } from '@ljn/reclame'
import styled from '@xstyled/styled-components'

export const Root = styled(ProgressWrapper)`
	margin-top: 0.25rem;

	> :first-child {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		column-gap: 0.125rem;
	}
`

export const ProgressRectangle = styled.div`
	background-color: ${({ theme: { colors }, isActive, inactiveProgressColor }) =>
		isActive ? colors.primary6 : inactiveProgressColor || colors.background1};
	width: 2.15625rem;
	height: 0.5rem;
`

export const ProgressLabel = styled.label`
	margin-left: 0.375rem;
`
