import { isArray, isEmpty, isNil, pickBy } from 'lodash'
import { toggleItem } from '@ljn/utils'

export const mapFiltersService = (filters) => {
	if (isEmpty(filters)) {
		return {}
	}

	return pickBy(
		{
			...filters,
		},
		(value) => (isArray(value) ? !isEmpty(value) : !isNil(value))
	)
}

export const updateCheckedProjectsService = (oldProjectIds, newProjectsIds, forceAll) => {
	if (forceAll === false) {
		return []
	}

	if (isEmpty(newProjectsIds)) {
		return oldProjectIds
	}

	let projectIds = [...(oldProjectIds || [])]

	if (!forceAll) {
		newProjectsIds.forEach((id) => {
			toggleItem(projectIds, id, { mutateArray: true })
		})
	} else {
		projectIds = [...oldProjectIds, ...newProjectsIds.filter((id) => !oldProjectIds.includes(id))]
	}

	return projectIds
}
