import PropTypes from '@ljn/prop-types'
import React, { Component } from 'react'

class ErrorBoundary extends Component {
	state = {
		error: null,
	}

	static getDerivedStateFromError(error) {
		return { error }
	}

	componentDidCatch(error, info) {
		console.error('Crash:', error, info)
	}

	render() {
		const { children } = this.props
		const { error } = this.state
		if (error) {
			return <div>Oops! Something went wrong!</div>
		}
		return children
	}
}

ErrorBoundary.propTypes = {
	children: PropTypes.node.isRequired,
}

export default ErrorBoundary
