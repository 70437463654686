import { fork, put, take, takeEvery } from 'redux-saga/effects'
import LanguageDetector from 'i18next-browser-languagedetector'
import i18n from 'i18next'

import { FETCH_BUNDLE, fetchBundleFailure, fetchBundleSuccess } from 'shared/actions/i18nActions'

import { IMPORT_FILE_SUCCESS, importFile } from 'shared/actions/fsActions'

export function* watchFetchBundle() {
	yield takeEvery(FETCH_BUNDLE, fetchBundleSaga)
}

export function* fetchBundleSaga({ payload }) {
	const { namespace } = payload
	try {
		let language = payload.language
		if (!language) {
			const lngDetector = new LanguageDetector(i18n.services)
			language = lngDetector.detect()
		}

		if (!i18n.hasResourceBundle(language, namespace)) {
			yield put(importFile(`locales/${language}/${namespace}.json`))
			const {
				payload: { responseBody },
			} = yield take(IMPORT_FILE_SUCCESS)
			i18n.addResourceBundle(language, namespace, responseBody, false, true)
		}

		yield put(fetchBundleSuccess(namespace, i18n.getResourceBundle(language, namespace)))
	} catch (err) {
		yield put(fetchBundleFailure(namespace, err))
	}
}

export default function* i18nSaga() {
	yield fork(watchFetchBundle)
}
