import { cloneElement } from 'react'
import styled from '@xstyled/styled-components'

export const Root = styled(({ component, ...props }) => cloneElement(component, props))`
	position: relative;
	width: 100%;
	${({ theme }) => ({ ...theme.fonts.formField })};
	border: medium;
	border-radius: regular;
	padding: 0 0.5rem;
	min-height: 2rem;
	background-color: backgroundSecondary;

	:focus,
	:focus-visible,
	:focus-within {
		outline-offset: 2px;
		outline: var(--focus) solid 2px;
	}
	> div > div:nth-child(3) > div > div > div:last-child {
		display: flex;
		justify-content: flex-start;
		flex-direction: column;
		row-gap: 0.625rem;
		> div {
			padding: 0 0.5rem;
		}
	}

	> div > div:nth-child(3) > div > div > div:first-child {
		padding: 0 0.5rem;
	}

	input {
		${({ theme }) => ({ ...theme.fonts.formField })};
	}
`
