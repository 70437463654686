import { isNaN } from 'lodash'
import axios from 'axios'

const extraCommunes = [
	{
		code: '975',
		nom: 'Saint-Pierre-et-Miquelon',
	},
]

const getLocations = async (input) => {
	try {
		const cityQuery = !isNaN(+input) ? 'codePostal' : 'nom'
		const cities = await axios(`${process.env.REACT_APP_GEO_API_BASE_URL}/communes?${cityQuery}=${input}`)
		const extraCities = extraCommunes.filter((c) => c.nom.toLowerCase().includes(input.toLowerCase()))

		const query = !isNaN(+input) ? 'code' : 'nom'
		const departments = await axios(`${process.env.REACT_APP_GEO_API_BASE_URL}/departements?${query}=${input}`)
		const regions = await axios(`${process.env.REACT_APP_GEO_API_BASE_URL}/regions?${query}=${input}`)

		let departmentOptions = departments.data.map((department) => ({
			value: department.nom,
			label: `${department.nom}${department.code ? ` (${department.code})` : ''}`,
			type: 'departement',
			code: department.code,
		}))

		let regionOptions = regions.data.map((region) => {
			return {
				value: region.nom,
				label: `${region.nom}`,
				type: 'region',
				code: region.code,
			}
		})

		const cityOptions = []
		cities.data.forEach((city) => {
			cityOptions.push({
				value: city.nom,
				label: `${city.nom}${city.codeDepartement ? ` (${city.codeDepartement})` : ''}`,
				type: 'city',
				code: city.code,
			})
		})
		extraCities.forEach((extraCity) => {
			cityOptions.push({
				value: extraCity.code,
				label: `${extraCity.nom}${extraCity.code ? ` (${extraCity.code})` : ''}`,
			})
		})

		// remove Department with same value as city
		// ex: Paris (75) in Communes AND Departement
		// Keep only Paris (75) in Communes
		departmentOptions = departmentOptions.filter((departmentOption) => {
			return !cityOptions.filter((city) => city.label === departmentOption.label).length
		})

		regionOptions = regionOptions.filter((region) => {
			return !region.label.includes('Réunion')
		})

		let otherOptions = []
		if ('France métropolitaine'.toUpperCase().includes(input.toUpperCase())) {
			otherOptions.push({
				code: '',
				label: 'France métropolitaine',
				type: 'region',
				value: 'France métropolitaine',
			})
		}

		return [
			{
				label: 'Régions',
				options: regionOptions,
			},
			{
				label: 'Départements',
				options: departmentOptions,
			},
			{
				label: 'Communes',
				options: cityOptions,
			},
			{
				label: 'Autres',
				options: otherOptions,
			},
		]
	} catch (error) {
		throw new Error(error)
	}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	get: getLocations,
}
