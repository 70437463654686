import axios from 'axios'

const getTargetedAudience = async (input) => {
	try {
		const { data: result } = await axios(
			`${window._env_.REACT_APP_BASE_URL}/api/projects/lists/autocomplete?field=targetedAudience&q=${input}`
		)

		return result.data.map((label) => ({ label, value: label }))
	} catch (error) {
		throw new Error(error)
	}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	get: getTargetedAudience,
}
