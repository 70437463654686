import { Provider } from 'react-redux'
import { ThemeProvider } from '@xstyled/styled-components'
import { createStore } from 'redux-dynamic-modules'
import { getSagaExtension } from 'redux-dynamic-modules-saga'
import { initReactI18next } from 'react-i18next'
import { i18n as reclameI18n } from '@ljn/reclame'
import { registerLocale } from 'react-datepicker'
import Modal from 'react-modal'
import React, { Suspense } from 'react'
import Tracker from '@openreplay/tracker'
import fr from 'date-fns/locale/fr'
import i18n from 'i18next'

import theme from '__theme__'

import App from 'shared/components/app/App'
import Loader from 'shared/components/loader/Loader'

import rootModule from 'modules/root'

if (process.env.REACT_APP_OPENREPLAY_KEY) {
	const tracker = new Tracker({
		projectKey: process.env.REACT_APP_OPENREPLAY_KEY,
		ingestPoint: process.env.REACT_APP_OPENREPLAY_INGEST,
	})
	tracker.start()
}

Modal.setAppElement('#root')

registerLocale('fr', fr)

i18n.use(initReactI18next).init({
	lng: process.env.REACT_APP_DEFAULT_LANGUAGE,
	fallbackLng: process.env.REACT_APP_DEFAULT_LANGUAGE,
	interpolation: {
		escapeValue: false,
	},
	react: {
		bindI18nStore: 'added',
	},
})

i18n.on('languageChanged', (lng) => reclameI18n.changeLanguage(lng))

const store = createStore(
	{
		extensions: [getSagaExtension()],
	},
	rootModule
)

const Root = () => (
	<Provider store={store}>
		<Suspense fallback={<Loader ariaLabel={"Chargement de l'application en cours..."} />}>
			<ThemeProvider theme={theme}>
				<App />
			</ThemeProvider>
		</Suspense>
	</Provider>
)

export default Root
