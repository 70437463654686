import { isEmpty } from 'lodash'
import { useTheme } from '@xstyled/styled-components'
import CreatableSelect from 'react-select/creatable'
import PropTypes from '@ljn/prop-types'
import React from 'react'
import ReactSelect from 'react-select'

import { BOTTOM, TOP } from 'shared/enums/selectTypes'

import * as Styled from 'shared/components/select/__styles__/Select.styles'

const Select = ({ name, options, menuPlacement, isTagMode, ...others }) => {
	const theme = useTheme()

	return (
		<Styled.Root
			component={isTagMode ? <CreatableSelect /> : <ReactSelect />}
			menuPlacement={menuPlacement}
			components={{
				DropdownIndicator: () => null,
				IndicatorSeparator: () => null,
				IndicatorsContainer: () => null,
				...(isEmpty(options) ? { Menu: () => null } : {}),
			}}
			placeholder={null}
			{...(name ? { id: name, name } : {})}
			options={options}
			cropWithEllipsis={false}
			styles={{
				control: () => ({
					appearance: 'none',
					display: 'block',
					width: '100%',
					height: '100%',
					overflow: 'hidden',
				}),
				valueContainer: () => ({
					display: 'flex',
					alignItems: 'center',
					position: 'relative',
					width: '100%',
					height: '100%',
				}),
				menu: (base) => ({
					...base,
					backgroundColor: theme.colors.backgroundSecondary,
					borderRadius: theme.radii.regular,
					boxShadow: theme.shadows.medium,
					left: 0,
					right: 0,
				}),
				option: (base, { isSelected, isFocused }) => ({
					...theme.fonts.formField,
					background: isSelected || isFocused ? theme.colors.backgroundPrimary : 'transparent',
					'&:hover': {
						backgroundColor: theme.colors.backgroundPrimary,
						color: theme.fonts.formField.color,
					},
					lineHeight: '2rem',
					padding: '0 0.5rem',
				}),
				menuList: (base) => ({
					...base,
					display: 'flex',
					justifyContent: 'flex-start',
					flexDirection: 'column',
					maxHeight: '10.5rem',
				}),
				multiValueLabel: (provided) => ({
					...provided,
					whiteSpace: 'normal',
				}),
			}}
			{...others}
		/>
	)
}

export default Select

Select.propTypes = {
	/** Defines the name and the id of select when it used in form.*/
	name: PropTypes.string,
	menuPlacement: PropTypes.oneOfEnum(TOP, BOTTOM),
	/** Defines the options of the menu, if options is null or an empty array the menu is disabled.*/
	options: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			value: PropTypes.string,
		})
	),
	/** Defines if the Select manage tag or menu. */
	isTagMode: PropTypes.bool,
}

Select.defaultProps = {
	name: null,
	menuPlacement: BOTTOM,
	options: [],
	isTagMode: false,
}
