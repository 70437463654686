import axios from 'axios'

const getKeyWords = async (input) => {
	try {
		const { data: result } = await axios(
			`${window._env_.REACT_APP_BASE_URL}/api/projects/lists/autocomplete?field=keyword&q=${input}`
		)
		return result.data.map(({ title, id }) => ({ label: title, value: id }))
	} catch (error) {
		throw new Error(error)
	}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	get: getKeyWords,
}
