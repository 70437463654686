import '@gouvfr/dsfr/dist/component/badge/badge.min.css'
import '@gouvfr/dsfr/dist/component/button/button.min.css'
import '@gouvfr/dsfr/dist/component/card/card.min.css'
import '@gouvfr/dsfr/dist/core/core.min.css'
import {
	ToggleButton,
	Button as _Button,
	Checkbox as _Checkbox,
	Form as _Form,
	TextField as _TextField,
} from '@ljn/reclame'
import styled from '@xstyled/styled-components'

export const Root = styled.div`
	position: relative;
	min-height: 100%;
	transition: ${({ theme }) => theme.transitions.width};
	width: ${({ $isDrawerOpen }) => ($isDrawerOpen ? '22.125rem' : '1rem')};
	background-color: backgroundPrimary;
	z-index: drawer;
	${({ isOnHomePage, theme }) =>
		isOnHomePage ? `box-shadow: ${theme.shadows.medium}` : `border-right: ${theme.borders.regular}`};
	${({ isOnHomePage, isOnProjectsPage }) => `display: ${isOnHomePage || isOnProjectsPage ? 'block' : 'none'}`};
`

export const TitleContainer = styled.div``

export const SearchContainer = styled.div`
	padding: 2rem 1.5rem;
	height: 100%;
	overflow: auto;
`

export const Title = styled.h1`
	${({ theme }) => ({ ...theme.fonts.title })};
	margin-bottom: 0.5rem;
	position: relative;
	display: flex;
	flex-direction: column;

	:after {
		content: '';
		width: 4.5rem;
		height: 0.25rem;
		margin-top: 0.5rem;
		background-color: primary4;
	}
`

export const ResultFiltersContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: auto;
`

export const ResultFiltersContent = styled.div`
	display: flex;
	flex-wrap: wrap;
	row-gap: 0.5rem;
	padding: 0 1.5rem;

	> * {
		margin-bottom: 0.5rem;
		white-space: nowrap;
	}

	> *:not(:last-child) {
		margin-right: 1rem;
	}
`

export const SemiTitle = styled.h2`
	${({ theme }) => ({ ...theme.fonts.semiTitle })};
	margin: 0;
	position: relative;
	display: flex;
	flex-direction: column;

	:after {
		content: '';
		width: 4.5rem;
		height: 0.25rem;
		margin-top: 0.5rem;
		background-color: primary4;
	}
`

export const Label = styled.div`
	margin-right: 0.5rem;
`

export const TagContainer = styled.div`
	display: flex;
	align-items: center;
`

export const ActionsContainer = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 0.75rem 1.5rem 1rem 1.5rem;
	border-bottom: regular;
`

export const ProjectsContainer = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 0.5rem;
	flex: 1;
	padding: 0.5rem 0.75rem 0.5rem 0.5rem;
`

export const Button = styled(_Button)``

export const Checkbox = styled(_Checkbox)`
	:first-child {
		margin-top: 0;
	}
`

export const ButtonDefault = styled(_Button)``

export const BackButton = styled(ButtonDefault)`
	margin: 1rem 1.5rem 1.5rem 1.5rem;
	width: fit-content;
`

export const Arrow = styled(ToggleButton)`
	position: absolute;
	top: 0;
	left: calc(100% + 0.0625rem);
	${({ theme }) => ({ ...theme.fonts.semiTitle })};
	&& {
		background-color: backgroundPrimary;
		box-shadow: controlButton;
		color: grey4;
	}

	&:not([class*='recl-button--isDisabled']).recl-button--isClickable:hover {
		&& {
			background-color: backgroundPrimary;
			background-image: unset;
			color: primary5;
			outline: ${({ theme }) => `1px solid ${theme.colors.primary5}`};
			outline-offset: -1px;
		}
	}

	> div {
		transition: transform 0.2s ease-in-out;
		${({ $isDrawerOpen }) => !$isDrawerOpen && 'transform: rotate(180deg); '};
	}
`

export const Form = styled(_Form)`
	position: relative;
`

export const TextField = styled(_TextField)``

export const SelectContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 1.5rem;

	> div {
		width: 100%;
	}
`

export const FieldLabel = styled.label`
	${({ theme }) => ({ ...theme.fonts.formField })};
	margin-bottom: 0.5rem;
`

export const SubmitContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 2rem;
	column-gap: 0.5rem;
`

export const LoadingFiltersContainer = styled.div`
	background-color: backgroundPrimary;
	width: 100%;
	height: 100%;
`

export const LoadingContainer = styled(LoadingFiltersContainer)`
	z-index: loading;
	position: absolute;
	opacity: 0.5;
`
