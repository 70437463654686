import { createBrowserHistory } from 'history'

let memoizedHistory = null
export const getHistory = () => {
	if (memoizedHistory === null) {
		memoizedHistory = createBrowserHistory()
	}

	return memoizedHistory
}

export const navigate = (path) => {
	const history = getHistory()
	return history.push(path)
}
