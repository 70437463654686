import { Button, Tag } from '@ljn/reclame'
import { isArray, isEmpty, isNil, isString } from 'lodash'
import { selectCurrentUser } from '@ljn/auth-front'
import { sortObjectsByLocale } from '@ljn/utils'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { useTheme } from '@xstyled/styled-components'
import { useTranslation } from 'react-i18next'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import {
	fetchPublishedProjects,
	postFilters,
	setHoveredProject,
	storeColors,
	storeUserColors,
	toggleCheckedProjects,
} from 'modules/root/actions/rootActions'

import {
	selectCheckedProjects,
	selectColors,
	selectFilters,
	selectHoveredProject,
	selectIsPostFiltersLoading,
	selectProjects,
	selectUserColors,
} from 'modules/root/selectors/rootSelectors'

import { initialState } from 'modules/root/reducers/rootReducer'

import { PROJECTS_SIZE } from 'shared/enums/paginationsTypes'
import { topics } from 'shared/enums/projectFilterTypes'

import AutoComplete from 'shared/components/autoComplete/AutoComplete'
import Dialog from 'shared/components/modal/Dialog'
import FiltersContent from 'shared/components/modal/FiltersContent'
import Loader from 'shared/components/loader/Loader'
import PaginationButtonContainer from 'shared/components/button/PaginationButtonContainer'
import ProjectCard, { SMALL } from 'shared/components/card/ProjectCard'
import Select from 'shared/components/select/Select'

import keyWordsSource from 'shared/components/autoComplete/sources/keyWordsSource'
import locationSource from 'shared/components/autoComplete/sources/locationSource'

import * as Styled from 'shared/components/layout/__styles__/Drawer.styles'

const Drawer = () => {
	const [values, setValues] = useState(initialState.filters)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [showFiltersResult, setShowFiltersResult] = useState(false)
	const [allSelectChecked, setAllSelectChecked] = useState(false)
	const [isDrawerOpen, setIsDrawerOpen] = useState(true)

	const filters = useSelector(selectFilters)
	const isPostFiltersLoading = useSelector(selectIsPostFiltersLoading)
	const projects = useSelector(selectProjects)
	const checkedProjects = useSelector(selectCheckedProjects)
	const hoveredProject = useSelector(selectHoveredProject)
	const colors = useSelector(selectColors)
	const userColors = useSelector(selectUserColors)
	const currentUser = useSelector(selectCurrentUser)

	const { t, i18n } = useTranslation('root')

	const { pathname } = useLocation()

	const dispatch = useDispatch()

	const theme = useTheme()

	useEffect(() => {
		if (!isEmpty(projects?.data) && !isEmpty(checkedProjects) && projects.data.length === checkedProjects.length) {
			setAllSelectChecked(true)
		} else {
			setAllSelectChecked(false)
		}
	}, [projects, checkedProjects])

	const getProjectColor = useCallback((projectId) => userColors[projectId.toString()] || null, [userColors])

	const othersFiltersCount = useMemo(
		() =>
			(!isEmpty(filters) &&
				Object.entries(filters).filter(
					([key, value]) =>
						!['localisation', 'location', 'radius', 'q', 'topics'].includes(key) &&
						(isArray(value) ? !isEmpty(value) : !isNil(value))
				)?.length) ||
			0,
		[filters]
	)

	const filtersExist = useMemo(
		() =>
			!isEmpty(values) &&
			Object.values(values).filter((value) => (isArray(value) || isString(value) ? !isEmpty(value) : !isNil(value)))
				?.length > 0,
		[values]
	)

	const topicsOptions = useMemo(
		() =>
			sortObjectsByLocale(
				topics.map((option) => ({
					value: t(`root:drawer.form.topicsOptions.${option}`),
					label: t(`root:drawer.form.topicsOptions.${option}`),
				})),
				'label',
				i18n.language
			),
		[t, i18n]
	)

	const topicsValue = useMemo(() => {
		// eslint-disable-next-line react/prop-types
		return values.topics?.map((value) => ({ value, label: value }))
	}, [values])

	const isOnHomePage = useMemo(() => pathname === '/', [pathname])

	const isOnProjectsPage = useMemo(() => pathname === '/projets', [pathname])

	useEffect(() => {
		if (isOnProjectsPage && showFiltersResult) {
			setShowFiltersResult(false)
		}
	}, [isOnProjectsPage, showFiltersResult])

	const _onOtherFiltersClick = () => setIsModalOpen(true)

	const _onCloseModalClick = () => setIsModalOpen(false)

	const _onUpdateSearchButtonClick = () => setShowFiltersResult(false)

	const _onSelectAllChange = (e) => {
		const projectIds = projects.data.map(({ id }) => id)
		if (!isEmpty(projects.data)) {
			dispatch(toggleCheckedProjects(projectIds, e.target.checked))
		}

		setAllSelectChecked(e.target.checked)
	}

	const _onSelectProject = useCallback((id) => () => dispatch(toggleCheckedProjects([id])), [dispatch])

	const _onPaletteChange = useCallback((colors) => dispatch(storeColors(colors)), [dispatch])

	const _onPaletteSelect = useCallback(
		(id) => (color) => {
			return dispatch(storeUserColors({ ...userColors, [id]: color }))
		},
		[dispatch, userColors]
	)

	const _onMoreProjectClick = useCallback(() => {
		dispatch(fetchPublishedProjects(projects.index + 1, PROJECTS_SIZE, null, filters))
	}, [projects, dispatch, filters])

	const _onSingleSelectChange = (callback) => (option) => callback?.(option)

	const _onMultiSelectChange = (callback) => (options) => callback?.(options.map(({ value }) => value))

	const _onInputChange = (callback) => (e) => {
		e.stopPropagation()
		return callback?.(e.target.value)
	}
	const _onFormChange = (type) => (value) => setValues((previous) => ({ ...previous, [type]: value }))

	const _onFormChangeControlNaN = (type) => (value) => {
		setValues((previous) => ({ ...previous, [type]: isNaN(value) ? previous[type] : !value ? undefined : value }))
	}

	const _onSubmit = useCallback(
		(newFilters) => {
			let newValues = isOnHomePage ? { ...newFilters, localisation: null } : newFilters

			newValues = {
				...newValues,
				...(!!newValues.q && { q: newValues.q.label }),
			}
			if (isOnHomePage) {
				setShowFiltersResult(true)
			}
			dispatch(postFilters(newValues))
		},
		[dispatch, isOnHomePage]
	)

	const _onSubmitClick = useCallback(() => _onSubmit(values), [_onSubmit, values])

	const _onClean = useCallback(() => {
		setValues(initialState.filters)
		dispatch(postFilters(initialState.filters))
	}, [dispatch])

	const _onCardHover = useCallback(
		(projectId) => () => {
			if (hoveredProject !== projectId) {
				dispatch(setHoveredProject(projectId))
			}
		},
		[hoveredProject, dispatch]
	)

	const _onCardLeave = useCallback(() => {
		dispatch(setHoveredProject(null))
	}, [dispatch])

	const _onArrowClick = (e) => {
		setIsDrawerOpen((previous) => !previous)
	}

	const _onTransitionEnd = ({ propertyName }) => {
		if (propertyName === 'width') {
			//Used for the leaflet to detect this event so that the map is automatically resized
			window.dispatchEvent(new Event('resize'))
		}
	}

	const renderProjects = useMemo(
		() =>
			!isEmpty(projects?.data) &&
			projects.data.map((project, index) => (
				<ProjectCard
					key={`${project.id}_${index}`}
					isChecked={checkedProjects.includes(project.id)}
					onCheck={_onSelectProject(project.id)}
					spotColor={getProjectColor(project.id)}
					paletteColors={colors}
					onPaletteChange={_onPaletteChange}
					onPaletteSelect={_onPaletteSelect(project.id)}
					variant={SMALL}
					onMouseOver={_onCardHover(project.id)}
					onMouseLeave={_onCardLeave}
					{...project}
				/>
			)),
		[
			projects,
			checkedProjects,
			colors,
			getProjectColor,
			_onSelectProject,
			_onPaletteChange,
			_onPaletteSelect,
			_onCardHover,
			_onCardLeave,
		]
	)

	return (
		<Styled.Root
			isOnHomePage={isOnHomePage}
			isOnProjectsPage={isOnProjectsPage}
			$isDrawerOpen={!isOnHomePage || isDrawerOpen}
			onTransitionEnd={_onTransitionEnd}
		>
			{isOnHomePage && (
				<>
					<Styled.Arrow
						size="sm"
						ariaLabel={t(`root:drawer.${isDrawerOpen ? 'ariaClose' : 'ariaOpen'}`)}
						icon="arrow-left-s-line"
						$isDrawerOpen={isDrawerOpen}
						onClick={_onArrowClick}
						tabIndex={-1}
					/>
				</>
			)}
			{(!isOnHomePage || isDrawerOpen) && (
				<>
					{showFiltersResult ? (
						<Styled.ResultFiltersContainer>
							<Styled.BackButton
								data-testid="__update-search-button__"
								variant="secondary"
								icon="arrow-left-s-line"
								onClick={_onUpdateSearchButtonClick}
							>
								{t('root:drawer.updateSearch')}
							</Styled.BackButton>
							{!isPostFiltersLoading ? (
								<>
									<Styled.ResultFiltersContent>
										<Styled.SemiTitle>
											{t('root:drawer.titleProject', { count: parseInt(projects.total) })}
										</Styled.SemiTitle>
										<Styled.TagContainer>
											<Styled.Label>{projects.projectsNotFinished}</Styled.Label>
											<Tag variant="inProgress">{t('root:tag.inProgress')}</Tag>
										</Styled.TagContainer>
										<Styled.TagContainer>
											<Styled.Label>{projects.projectsFinished}</Styled.Label>
											<Tag variant="done">{t('root:tag.done', { count: parseInt(projects.projectsFinished) })}</Tag>
										</Styled.TagContainer>
									</Styled.ResultFiltersContent>
									<Styled.ActionsContainer>
										<Styled.Checkbox
											forceChecked={allSelectChecked}
											aria-describedby="selectProjects"
											onCheck={_onSelectAllChange}
										>
											{t('root:drawer.selectAll')}
										</Styled.Checkbox>
										<p className="recl-hidden" id="selectProjects">
											{t('root:drawer.selectProjectsInfo')}
										</p>
									</Styled.ActionsContainer>
									<Styled.ProjectsContainer>
										{renderProjects}
										<PaginationButtonContainer showButton={parseInt(projects?.total) !== projects?.data?.length}>
											<Styled.Button onClick={_onMoreProjectClick}>{t('root:pagination.moreProject')}</Styled.Button>
										</PaginationButtonContainer>
									</Styled.ProjectsContainer>
								</>
							) : (
								<Styled.LoadingFiltersContainer>
									<Loader ariaLabel={t('root:drawer.form.postFilterLoadingAriaLabel')} />
								</Styled.LoadingFiltersContainer>
							)}
						</Styled.ResultFiltersContainer>
					) : (
						<Styled.SearchContainer>
							<Styled.TitleContainer>
								<Styled.Title>{t('root:drawer.search')}</Styled.Title>
							</Styled.TitleContainer>
							<Styled.Form onSubmit={_onSubmitClick}>
								{isPostFiltersLoading && (
									<Styled.LoadingContainer>
										<Loader ariaLabel={t('root:drawer.form.postFilterLoadingAriaLabel')} />
									</Styled.LoadingContainer>
								)}
								<Styled.SelectContainer>
									<Styled.FieldLabel htmlFor="localisation">{t('root:drawer.form.keyWords')}</Styled.FieldLabel>
									<AutoComplete
										name="keyWords"
										source={keyWordsSource}
										value={values.q}
										isTagMode={true}
										placeholder={t('root:drawer.form.keyWordsPlaceholder')}
										noOptionsMessage={t('root:autoComplete.noOptions')}
										loadingMessage={t('root:autoComplete.loading')}
										onChange={_onSingleSelectChange(_onFormChange('q'))}
									/>
								</Styled.SelectContainer>
								<Styled.SelectContainer>
									<Styled.FieldLabel htmlFor="localisation">{t('root:drawer.form.localisation')}</Styled.FieldLabel>
									<AutoComplete
										name="localisation"
										source={locationSource}
										value={values.location}
										placeholder={t('root:drawer.form.localisationPlaceholder')}
										noOptionsMessage={t('root:autoComplete.noOptions')}
										loadingMessage={t('root:autoComplete.loading')}
										onChange={_onSingleSelectChange(_onFormChange('location'))}
									/>
								</Styled.SelectContainer>
								<Styled.SelectContainer>
									<Styled.FieldLabel htmlFor="topics">{t('root:drawer.form.topics')}</Styled.FieldLabel>
									<Select
										name="topics"
										placeholder={t('root:drawer.form.topicsPlaceholder')}
										isMulti
										options={topicsOptions}
										value={topicsValue}
										onChange={_onMultiSelectChange(_onFormChange('topics'))}
									/>
								</Styled.SelectContainer>
								<Styled.ButtonDefault
									variant="secondary"
									data-testid="__other-filter-button__"
									onClick={_onOtherFiltersClick}
									icon="diplp-filter"
								>
									{t('root:drawer.form.otherFilters')}
									{!!othersFiltersCount && t('root:drawer.form.otherFiltersCount', { count: othersFiltersCount })}
								</Styled.ButtonDefault>
								<Styled.SubmitContainer>
									{filtersExist && (
										<Styled.ButtonDefault variant="secondary" onClick={_onClean}>
											{t('root:drawer.form.clean')}
										</Styled.ButtonDefault>
									)}
									<Button type="submit" onClick={() => null}>
										{t('root:drawer.form.search')}
									</Button>
								</Styled.SubmitContainer>
							</Styled.Form>
						</Styled.SearchContainer>
					)}
					<Dialog
						title={t('root:modal.title')}
						onClose={_onCloseModalClick}
						closeButtonAriaLabel={t('root:modal.ariaLabelButtonClose')}
						isOpen={isModalOpen}
						style={{
							overlay: {
								zIndex: 1000,
								width: '100%',
								height: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								backgroundColor: theme.colors.overlay,
							},
							content: {
								width: '100%',
								maxWidth: theme.widths.filtersModal,
								inset: null,
								boxShadow: theme.shadows.modal,
								padding: 0,
								overflow: 'hidden',
							},
						}}
						contentLabel={t('root:drawer.form.otherFilters')}
					>
						<FiltersContent
							filters={values}
							isPostFiltersLoading={isPostFiltersLoading}
							onChange={_onFormChange}
							onChangeControlled={_onFormChangeControlNaN}
							onSubmit={_onSubmit}
							onClose={_onCloseModalClick}
							onSingleSelectChange={_onSingleSelectChange}
							onMultiSelectChange={_onMultiSelectChange}
							onInputChange={_onInputChange}
							onSliderChange={_onInputChange}
							onClean={_onClean}
							isLogged={!!currentUser}
						/>
					</Dialog>
				</>
			)}
		</Styled.Root>
	)
}

export default Drawer
