import styled from '@xstyled/styled-components'

export const Root = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: backgroundPrimary;
`

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	background-color: backgroundSecondary;
	width: 50%;
	height: 50%;
	box-shadow: modal;
	padding: 2rem;
	max-width: 32rem;
`

export const Content = styled.div`
	flex: 1;
	display: flex;
	justify-content: center;
	flex-direction: column;
`

export const LogoContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
`

export const Operator = styled.span`
	width: 10.8125rem;
	${({ theme }) => ({ ...theme.fonts.smallBold })};
	margin: 0 1.5rem;
`
