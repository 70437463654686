export const MAX_BENEFICIARIES = 1600000
export const MIN_BENEFICIARIES = 0

export const MAX_BUDGET = 1600000
export const MIN_BUDGET = 0

export const METROPOLIS_CONTRACTING = 'metropolisContracting'
export const DEPARTMENTAL_CONTRACTING = 'departmentalContracting'
export const REGION_CONTRACTING = 'regionContracting'
export const CLP = 'clp'
export const NATIONAL_GRANTS = 'nationalGrants'

export const MOVE_TOWARDS = 'moveTowards'
export const MOBILITY = 'mobility'
export const CHILD_OFFER = 'childOffer'
export const CHILD_FORMATION = 'childFormation'
export const FOOD = 'food'
export const PARENTHOOD_AND_SOCIAL_CENTERS = 'parenthoodAndSocialCenters'
export const ACCOMMODATION = 'accommodation'
export const HEALTH = 'health'
export const SCHOOL_SUCCESS = 'schoolSuccess'
export const YOUTH_INTEGRATION = 'youthIntegration'
export const ASE = 'ase'
export const TRAINING_COURSE = 'trainingCourse'
export const SUPPORT_OFFER = 'supportOffer'
export const RIGHTS_ACCESS = 'rightsAccess'
export const SOCIAL_WORK_TRAINING = 'socialWorkTraining'
export const PARTICIPATION = 'participation'
export const CORPORATE_ENGAGEMENT = 'corporateEngagement'
export const RURALITY = 'rurality'
export const PEER_HELP = 'peerHelp'
export const SANITY = 'sanity'
export const DIVERSITY = 'diversity'
export const NUMERIC_INSERTION = 'numericInsertion'

export const topics = [
	CHILD_OFFER,
	CHILD_FORMATION,
	PARENTHOOD_AND_SOCIAL_CENTERS,
	FOOD,
	ACCOMMODATION,
	HEALTH,
	SCHOOL_SUCCESS,
	YOUTH_INTEGRATION,
	ASE,
	MOVE_TOWARDS,
	MOBILITY,
	TRAINING_COURSE,
	SUPPORT_OFFER,
	RIGHTS_ACCESS,
	SOCIAL_WORK_TRAINING,
	PARTICIPATION,
	CORPORATE_ENGAGEMENT,
	RURALITY,
	PEER_HELP,
	SANITY,
	DIVERSITY,
	NUMERIC_INSERTION,
]

export const fundingScope = [METROPOLIS_CONTRACTING, DEPARTMENTAL_CONTRACTING, REGION_CONTRACTING, CLP, NATIONAL_GRANTS]

const filterTypes = [MAX_BENEFICIARIES, MIN_BENEFICIARIES, MAX_BUDGET, MIN_BUDGET]

export default filterTypes
