import { Heading, LinkInReactRouter, Paragraph } from '@ljn/reclame'
import { Link } from 'react-router-dom'
import PropTypes from '@ljn/prop-types'
import React from 'react'

import Page from 'shared/components/page/Page'

import * as Styled from 'shared/components/page/__styles__/NoMatchPage.styles'

const NoMatchPage = ({ title, message, redirect }) => {
	return (
		<Page>
			<Styled.Root>
				<Heading as="h1">{title}</Heading>
				<Paragraph>{message}</Paragraph>
				<Link to="/" component={LinkInReactRouter}>
					{redirect}
				</Link>
			</Styled.Root>
		</Page>
	)
}

NoMatchPage.propTypes = {
	title: PropTypes.string,
	message: PropTypes.string,
	redirect: PropTypes.string,
}

export default NoMatchPage
