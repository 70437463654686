import { Link } from 'react-router-dom'
import { Checkbox as _Checkbox } from '@ljn/reclame'
import { pickForeground } from '@ljn/utils'
import styled from '@xstyled/styled-components'

import { LARGE, SMALL } from 'shared/components/card/ProjectCard'

// jusqu'à refonte totale du système de style, ce style permet de surcharger le thème global par défaut, pour mettre celui du DSFR
export const CustomCSS = styled.div`
	a:not(.fr-btn) {
		box-shadow: none;
	}
	.fr-card {
		height: 450px;
		width: 340px;
		${({ isChecked, checkedColor }) => isChecked && `background-color: ${checkedColor}`};
	}

	.fr-progress {
		position: absolute;
		bottom: 85px;
	}

	.fr-card__footer {
		position: absolute;
		bottom: 0;
	}
	.fr-card__desc {
		overflow: hidden;
		margin-top: 0px;
	}
	.recl-link {
		display: inline;
	}
	.fr-card__title {
		font-size: 1rem;
	}
`

export const Portal = styled.div`
	position: relative;
`

export const Root = styled.div`
	// css désactivé pour ne pas surcharger le dsfr
	/*border: normal;
	border-radius: regular;
	transition: all ease-in-out 0.2s;
	overflow: hidden;
	cursor: pointer;
	display: flex;
	flex-direction: column;

	${({ theme, variant }) =>
		variant === SMALL && `background-color: ${theme.colors.backgroundSecondary}; width: 100%; height: 22rem;`}
	${({ variant }) => variant === LARGE && `width: 20.625rem; height: 26.6875rem;`}
	${({ theme, isChecked }) => isChecked && `background-color: ${theme.colors.primary1}`};

	:hover {
		border-color: primary5;
	}*/
`

export const SpotContainer = styled.div`
	position: relative;
`

export const Spot = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: round;
	width: ${({ theme }) => theme.widths.marker};
	height: ${({ theme }) => theme.heights.marker};
	${({ theme }) => ({ ...theme.fonts.regular })};
	background-color: ${({ theme, color }) => color || theme.colors.primary6};
	color: ${({ theme, color }) =>
		!!color
			? pickForeground(color, [theme.colors.backgroundSecondary, theme.colors.grey4])
			: theme.colors.backgroundSecondary};
	border: marker;
	cursor: pointer;
`

export const Tooltip = styled.div`
	position: relative;
	background-color: grey4;
	box-shadow: controlButton;
	border-radius: regular;
	margin-left: 0.9rem;
	display: flex;
	color: backgroundSecondary;
	padding: 0.5rem 0.325rem;
	top: -0.7rem;
`

export const PaletteTooltip = styled.div`
	position: relative;
	background-color: backgroundSecondary;
	box-shadow: controlButton;
	border-radius: regular;
	margin-left: 0.9rem;
	display: flex;
	flex-direction: column;
	//This calculation is necessary because the library removes 5px from its maximum height when performing a
	// transformation to position itself
	max-height: calc(100vh - 5px);
	left: 16rem;
	top: -0.5rem;
`

export const Container = styled.div`
	padding: 1rem;
	display: flex;
	flex: 1;
	overflow: hidden;
`

export const Checkbox = styled(_Checkbox)`
	height: fit-content;
	:first-child {
		margin-top: 1rem;
		margin-right: 1.5rem;
	}
`

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	overflow: hidden;
`

export const Row = styled.div`
	margin: 0.25rem 0 0 0;
`

export const Title = styled.h3`
	${({ theme }) => ({ ...theme.fonts.textTitle })};
	margin: 0;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	-webkit-box-pack: end;
`

export const Localisation = styled.p`
	${({ theme }) => ({ ...theme.fonts.small })};
	max-height: 2.75rem;
	overflow: hidden;
	color: grey4;
	margin: 0;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
`

export const Paragraph = styled.p`
	${({ theme }) => ({ ...theme.fonts.medium })};
	color: grey3;
	overflow: hidden;
	position: relative;
	margin: 0;
`

export const RowDescription = styled.div`
	max-height: 5.5rem;
	margin-top: 0.75rem;
`

export const Description = styled(Paragraph)`
	max-height: 5.5rem;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
`

export const LabelTitle = styled.h6`
	${({ theme }) => ({ ...theme.fonts.bold })};
	margin-top: 0.75rem;
	margin-bottom: 0.25rem;
	color: grey3;
`

export const LabelDescription = styled(Paragraph)`
	max-height: 2.75rem;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
`

export const LeaderLabelDescription = styled(LabelDescription)`
	-webkit-line-clamp: 1;
`

export const Footer = styled.div`
	padding: 0.625rem 1rem;
	display: flex;
	justify-content: flex-end;
	border-top: normal;
`

export const Button = styled(Link)`
	${({ theme }) => ({ ...theme.fonts.medium })};
	border: medium;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.3125rem 1rem;
	box-shadow: unset;
`
