import { call, fork, put, takeEvery } from 'redux-saga/effects'

import {
	DOWNLOAD_FILE,
	IMPORT_FILE,
	downloadFileFailure,
	downloadFileSuccess,
	importFileFailure,
	importFileSuccess,
} from 'shared/actions/fsActions'

import { downloadFileService, importFileService } from 'shared/services/fsServices'

export function* watchImportFile() {
	yield takeEvery(IMPORT_FILE, importFileSaga)
}

export function* importFileSaga({ payload }) {
	const { transactionId } = payload
	try {
		const { filePath } = payload
		const fileContent = yield call(importFileService, filePath)
		yield put(importFileSuccess(fileContent, transactionId))
	} catch (err) {
		yield put(importFileFailure(err, transactionId))
	}
}

export function* watchDownloadFile() {
	yield takeEvery(DOWNLOAD_FILE, downloadFileSaga)
}

export function* downloadFileSaga({ payload }) {
	const { transactionId } = payload
	try {
		const { content, fileName, mimeType } = payload

		yield call(downloadFileService, content, fileName, mimeType)

		yield put(downloadFileSuccess(transactionId))
	} catch (err) {
		yield put(downloadFileFailure(err, transactionId))
	}
}

export default function* fsSaga() {
	yield fork(watchImportFile)
	yield fork(watchDownloadFile)
}
