import { Link } from 'react-router-dom'
import { LinkInReactRouter } from '@ljn/reclame'
import { resolveProgressionValue } from 'shared/utils/progressUtils'
import { useTheme } from '@xstyled/styled-components'
import { useTranslation } from 'react-i18next'
import ProgressBar from 'shared/components/progressBar/ProgressBar'
import PropTypes from '@ljn/prop-types'
import React, { useCallback, useMemo } from 'react'

import { PROJECT_DONE } from 'shared/enums/projectTypes'

import * as Styled from 'shared/components/card/__styles__/ProjectCard.styles'

export const SMALL = 'small'
export const LARGE = 'large'

const ProjectCard = ({
	id,
	isChecked,
	projectProgression,
	title,
	region,
	description,
	leader,
	topics,
	variant,
	onCheck,
	spotColor,
	paletteColors,
	onPaletteChange,
	onPaletteSelect,
	onMouseOver,
	onMouseLeave,
}) => {
	const { t } = useTranslation(['projects'])

	const theme = useTheme()

	const _onClick = useCallback((e) => !!onCheck && onCheck(e), [onCheck])

	const _onMouseOver = useCallback(() => onMouseOver?.(), [onMouseOver])

	const _onMouseLeave = useCallback(() => onMouseLeave?.(), [onMouseLeave])

	const progressionTagLabel = useMemo(
		() => (projectProgression ? (projectProgression === PROJECT_DONE ? 'done' : 'inProgress') : null),
		[projectProgression]
	)

	return (
		<Styled.Portal>
			<Styled.CustomCSS isChecked={isChecked} checkedColor={theme.colors.primary2}>
				<Styled.Root
					isChecked={isChecked}
					onClick={_onClick}
					variant={variant}
					onMouseOver={_onMouseOver}
					onMouseLeave={_onMouseLeave}
				>
					<div className="fr-card">
						<div className="fr-card__body">
							<div className="fr-card__content">
								<ul className="fr-badges-group">
									<li>
										<p
											className={
												'fr-badge ' +
												(progressionTagLabel === 'done'
													? 'fr-badge--green-emeraude'
													: 'fr-badge--green-tilleul-verveine')
											}
										>
											{t(`root:tag.${progressionTagLabel}`)}
										</p>
									</li>
									{variant === SMALL && (
										<li>
											<Styled.SpotContainer>
												<Styled.Spot color={spotColor}>
													{topics?.length ? t(`cartography:topics.${topics[0]}.acronym`) : null}
												</Styled.Spot>
											</Styled.SpotContainer>
										</li>
									)}
								</ul>
								<div className="fr-card__start">
									<h4 className="fr-card__title">
										<Link to={{ pathname: `/projets/${id}`, state: { previous: '/' } }} component={LinkInReactRouter}>
											{title.length < 42 ? title : title.substring(0, 42) + '...'}
										</Link>
									</h4>
									<p className="fr-card__detail">{region?.label && region.label}</p>
									{leader && (
										<>
											<Styled.LabelTitle>{t('root:card.leader')}</Styled.LabelTitle>
											<div>
												<Styled.LeaderLabelDescription>{leader}</Styled.LeaderLabelDescription>
											</div>
										</>
									)}
								</div>
								<p className="fr-card__desc">
									<Styled.LabelTitle>{t('root:card.topics')}</Styled.LabelTitle>
									{description.length < 100 ? description : description.substring(0, 100) + '...'}
								</p>
							</div>
							<div className="fr-progress">
								<Styled.LabelTitle>{t('root:card.projectProgression')}</Styled.LabelTitle>
								<ProgressBar
									ariaLabel={t('root:card.projectProgression')}
									currentValue={resolveProgressionValue(projectProgression)}
									inactiveProgressColor={isChecked ? theme.colors.backgroundSecondary : null}
								/>
							</div>
							<div className="fr-card__footer">
								<ul className="fr-btns-group fr-btns-group--inline-reverse fr-btns-group--inline-lg">
									<li>
										<Styled.Checkbox ariaLabel={t('root:card.selectForExport', { title })} forceChecked={isChecked}>
											<label className="recl-hidden" />
										</Styled.Checkbox>
									</li>
									<li>
										<Link
											className="fr-btn fr-btn--secondary"
											to={{ pathname: `/projets/${id}`, state: { previous: '/' } }}
											component={LinkInReactRouter}
										>
											{t('root:card.details')}
										</Link>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</Styled.Root>
			</Styled.CustomCSS>
		</Styled.Portal>
	)
}

export default ProjectCard

ProjectCard.propTypes = {
	/** Used to determine the checkbox status and the background of the card. */
	isChecked: PropTypes.bool,
	/** The callback called when the card is clicked or the checkbox is checked.
	 * Used to change the value of isChecked. */
	onCheck: PropTypes.func,
	/** The callback called when the card is hovered. */
	onMouseOver: PropTypes.func,
	/** The callback called when the mouse leave the card. */
	onMouseLeave: PropTypes.func,
	/** Defines the variant of the component.
	 * It used to defined the content of the card and the background of the card.
	 */
	variant: PropTypes.oneOfEnum(SMALL, LARGE),
	id: PropTypes.stringOrNumber,
	title: PropTypes.string.isRequired,
	projectProgression: PropTypes.string,
	description: PropTypes.string,
	topics: PropTypes.arrayOf(PropTypes.string),
	leader: PropTypes.string,
	region: PropTypes.shape({
		label: PropTypes.string.isRequired,
	}),
	spotColor: PropTypes.string,
	paletteColors: PropTypes.arrayOf(
		PropTypes.arrayOf(
			PropTypes.shape({
				name: PropTypes.string,
				color: PropTypes.string,
				allowDelete: PropTypes.bool,
			})
		)
	),
	onPaletteChange: PropTypes.func,
	onPaletteSelect: PropTypes.func,
}

ProjectCard.defaultProps = {
	isChecked: false,
	id: null,
	title: null,
	projectProgression: null,
	description: null,
	leader: null,
	region: null,
	topics: null,
	variant: LARGE,
	spotColor: null,
	paletteColors: [],
	onPaletteChange: null,
	onHover: null,
	onCheck: null,
	onPaletteSelect: null,
}
